import axiosInstance from "./axiosInstance";

// Fetch paginated list of tickets
export const fetchTickets = async (
  page = 0,
  size = 10,
  sortColumn = "createdDate",
  sortDirection = "desc",
  searchTerm = ""
) => {
  try {
    const response = await axiosInstance.get("/tickets", {
      params: {
        page,
        size,
        sortColumn,
        sortDirection,
        searchTerm,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching tickets:", error);
    throw error;
  }
};

// Fetch tickets by createdBy
export const fetchTicketsByCreatedBy = async (
  createdBy,
  page = 0,
  size = 10,
  sortColumn = "createdDate",
  sortDirection = "desc",
  searchTerm = ""
) => {
  try {
    const response = await axiosInstance.get("/tickets/created-by", {
      params: {
        createdBy,
        page,
        size,
        sortColumn,
        sortDirection,
        searchTerm,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching tickets created by ${createdBy}:`, error);
    throw error;
  }
};

// Fetch a single ticket by ID
export const fetchTicketById = async (id) => {
  try {
    const response = await axiosInstance.get(`/tickets/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching ticket:", error);
    throw error;
  }
};

// Create a new ticket
export const createTicket = async (ticketData) => {
  try {
    const response = await axiosInstance.post("/tickets", ticketData);
    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
};

// Update an existing ticket
export const updateTicket = async (id, ticketData) => {
  try {
    const response = await axiosInstance.put(`/tickets/${id}`, ticketData);
    return response.data;
  } catch (error) {
    console.error("Error updating ticket:", error);
    throw error;
  }
};

// Delete a ticket
export const deleteTicket = async (id) => {
  try {
    const response = await axiosInstance.delete(`/tickets/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting ticket:", error);
    throw error;
  }
};

// Fetch ticket count by status
export const fetchTicketCountByStatus = async (status) => {
  try {
    const response = await axiosInstance.get("/tickets/status-count", {
      params: { status },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching ticket count for status ${status}:`, error);
    throw error;
  }
};

// Add feedback to a ticket
export const addFeedback = async (ticketId, feedbackData) => {
  try {
    const response = await axiosInstance.post(
      `/tickets/feedback/${ticketId}`,
      feedbackData
    );
    return response.data;
  } catch (error) {
    console.error("Error adding feedback:", error);
    throw error;
  }
};

// Fetch paginated feedback for a ticket
export const fetchFeedback = async (ticketId, page = 0, size = 5) => {
  try {
    const response = await axiosInstance.get(
      `/tickets/feedback/${ticketId}/list`,
      {
        params: { page, size },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching feedback:", error);
    throw error;
  }
};

// Search Admin
export const searchAdmin = async (
  searchTerm,
  filter = "",
  sort = "",
  page = 1,
  size = 10
) => {
  try {
    const response = await axiosInstance.get(`/tickets/searchAdmin`, {
      params: {
        page,
        size,
        filter,
        sort,
        searchTerm,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching admin search results:", error);
    throw error;
  }
};

// send email status
export const sendEmailStatus = async (ticket) => {
  try {
    const response = await axiosInstance.put(
      `/tickets/send-email-status`,
      ticket
    );
    return response.data;
  } catch (error) {
    console.error("Error saving agency:", error);
    throw error;
  }
};

// send email create ticket
export const sendEmailCreateTicket = async (ticket) => {
  try {
    const response = await axiosInstance.put(
      `/tickets/email-create-ticket`,
      ticket
    );
    return response.data;
  } catch (error) {
    console.error("Error saving agency:", error);
    throw error;
  }
};
