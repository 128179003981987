import React, { useState, useEffect, useRef } from "react";
import faqData from "./faq.json";
import "./FAQChatbox.css";

const ChatFAQ = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const chatBodyRef = useRef(null);

  // Auto-scroll to the bottom when chat history changes
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleCategoryClick = (category) => {
    setCurrentCategory(category);
    setChatHistory([
      ...chatHistory,
      { sender: "user", message: category.Category },
      { sender: "system", message: "Select a question to proceed:" },
    ]);
  };

  const handleQuestionClick = (question, answer) => {
    setChatHistory([
      ...chatHistory,
      { sender: "user", message: question },
      { sender: "system", message: answer },
    ]);
  };

  const handleBackToCategories = () => {
    setChatHistory([]);
    setCurrentCategory(null);
  };

  return (
    <div className="chat-container">
      <div className="chat-header">Hi! How can I help you today?</div>
      <div className="chat-body" ref={chatBodyRef}>
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            className={`chat-bubble ${chat.sender === "user" ? "user" : "system"}`}
          >
            {chat.message}
          </div>
        ))}
      </div>
      {!currentCategory && (
        <>
          {/* Add intro message */}
          {chatHistory.length === 0 && (
            <div className="chat-body">
            <div className="chat-bubble system">
              You may click one of the categories below to get started.
            </div>
            </div>
          )}
          <div className="chat-options">
            {faqData.map((category, index) => (
              <button
                key={index}
                className="chat-button"
                onClick={() => handleCategoryClick(category)}
              >
                {category.Category}
              </button>
            ))}
          </div>
        </>
      )}
      {currentCategory && (
        <div className="chat-options">
          {faqData
            .find((cat) => cat.Category === currentCategory.Category)
            ["Sub-Category"].map((item, index) => (
              <button
                key={index}
                className="chat-button"
                onClick={() => handleQuestionClick(item.FAQ, item.Answer)}
              >
                {item.FAQ}
              </button>
            ))}
          <button className="back-button" onClick={handleBackToCategories}>
            Back to Categories
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatFAQ;
