
import React, { useState, useEffect } from 'react';
import { ClaySelect, ClayInput } from '@clayui/form';
import { getProductAndForms } from "../../../../api/nebProductService";
import { getTotalSelloutRelatedForms } from '../../../../api/nebFormTemplateService';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import exportToPdf from './exportToPdf';

const FuelBalanceTableCheckingKtoeDP = ({ agencyId, checkingReport }) => {

  const [fbHeaders, setFBHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [plantRatio, setPlantRatio] = useState(0);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fuelValues, setFuelValues] = useState([]);
  const [productAndUnit, setProductAndUnit] = useState([]);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [currentUnit, setCurrentUnit] = useState("");
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [totalSelloutRelatedForms, setTotalSelloutRelatedForms] = useState([]);
  const [reportBasicInfo, setReportBasicInfo] = useState();
  const [totalSellouts, setTotalSellouts] = useState([]);



  const numInputStyle = {
    textAlign: "right",
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  };

  const selectInputStyle = {
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms(agencyId, 'FB');
        console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
        setProductAndFormsList(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTotalSelloutRelatedForms = async () => {
      try {
        const result = await getTotalSelloutRelatedForms();
        console.log('FETCHING TSR forms' + JSON.stringify(result, null, 2));
        setTotalSelloutRelatedForms(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    fetchTotalSelloutRelatedForms();
  }, []);





  const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, formCode, subtractive, inKtoe) => {
    const { value: inputValue } = e.target;
    let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue))

    console.log("inktoe: " + inKtoe)

    //Make value negative based on form type
    if (subtractive) {
      value = -Math.abs(value);
    }

    setFuelValues(prevFuelValues => {
      // Check if an object with matching index exists
      const exists = prevFuelValues.some(item => item.index === index);

      if (exists) {
        // Update the existing object
        return prevFuelValues.map(item => {
          if (item.index === index) {
            // Find the form
            let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
            let updatedForms;

            if (formIndex !== -1) {
              // Update the existing form
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map(category => {
                    if (category.formCode === formCode) {
                      return { formCode: formCode, value: value, description: formFieldName };
                    } else {
                      return category;
                    }
                  });

                  // If the category doesn't exist, add it
                  if (!updatedCategories.some(category => category.formCode === formCode)) {
                    updatedCategories.push({ formCode: formCode, value: value, description: formFieldName });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              // Add a new form
              updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: formCode, value: value, description: formFieldName }] }];
            }

            // Calculate the new balance
            const balance = updatedForms.reduce((sum, form) => {
              return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
            }, 0);

            const fixedBalance = Math.abs(balance) < 0.001 ? 0 : balance.toFixed(3);


            return { ...item, forms: updatedForms, balance: isNaN(Number(fixedBalance)) ? 0 : Number(fixedBalance), };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          index: index,
          balance: isNaN(Number(value)) ? 0 : Number(value),
          productName: productName,
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                { formCode: formCode, value: value, description: formFieldName }
              ]
            }
          ],
          inKtoe: inKtoe
        };

        // Calculate the new balance
        const balance = newItem.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0);

        const fixedBalance = Math.abs(balance) < 0.001 ? 0 : balance.toFixed(3);


        return [
          ...prevFuelValues,
          { ...newItem, balance: isNaN(Number(fixedBalance)) ? 0 : Number(fixedBalance) }
        ];
      }
    });
  };

  //plant efficiency calculation
  useEffect(() => {
    console.log("fuels: " + JSON.stringify(fuelValues, null, 2))
    let f2Sum = 0;
    let f3Sum = 0;

    fuelValues.forEach((fuel) => {
      const product = productAndUnit.find(item => item.fieldName === fuel.productName);
      const inKtoe = product ? product.inKtoe : 0;
      fuel.forms.forEach((form) => {
        form.categories.forEach((category) => {
          if (category.formCode === "F2-PS") {
            // Convert to ktoe and ensure positive value
            f2Sum += Math.abs(category.value * inKtoe);
          }
          if (category.formCode === "F3-CO") {
            // Convert to ktoe and ensure positive value
            f3Sum += Math.abs(category.value * inKtoe);
          }
        });
      });
    });

    let percent = (f2Sum / f3Sum) * 100;

    setPlantRatio(isNaN(Number(percent)) ? 0 : Number(percent.toFixed(2)));
    console.log(" ratio fuel: " + percent);
  }, [fuelValues, productAndUnit])

  useEffect(() => {
    console.log("products: ", JSON.stringify(productAndUnit, null, 2));
  }, [productAndUnit]);

  const handleUnitChange = (e, index, productName, inKtoe) => {
    const { value: unit } = e.target;

    setProductAndUnit(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.fieldName === productName);

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          if (item.fieldName === productName) {
            return {
              ...item,
              unit: unit,
              inKtoe: inKtoe
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          fieldName: productName,
          unit: unit,
          inKtoe: inKtoe
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };


  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;
    const dynamicRows = productAndFormsList.map((product, index) => {
      const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
      console.log("fobj: " + JSON.stringify(fuelObject, null, 2))
      return {
        no: index + 1,
        product: product.productName,
        balance: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{fuelObject ? fuelObject.balance.toFixed(3) : 0}</span>),
        unit: (
          <ClaySelect
            style={selectInputStyle}
            disabled
            key={`selectUnit_${product.productName}`}
            id={`selectUnit_${product.productName}`}
            onChange={(e) => handleUnitChange(e, index, product.productName, product.units[e.target.selectedIndex - 1] ? product.units[e.target.selectedIndex - 1].inKtoe : "")}>
            <option value={"ktoe"}>ktoe</option>
            {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        ),
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <ClayInput disabled
              key={`${index + 1}_${form.formFieldCode}`}
              style={numInputStyle}
              type="number"
              id={`num_${product.productName}_${form.formFieldCode}`}
              onChange={(e) => handleFuelValueChange(e, index + 1, product.productName, form.parentFormFieldCode, form.formFieldName, form.formFieldCode, form.subtractive)}
            />
          );
          return acc;
        }, {}),
        totalSellout: productAndFormsList[0].forms.some(form => form.parentFormFieldCode === "F4")
          ? fuelObject ?
            (() => {
              //total sell out value must always be +ve eventhough from subtractive form

              let currentF4Totals = fuelObject.forms.find(form => form.formCode === "F4")
                ?.categories.filter(category => totalSelloutRelatedForms.includes(category.formCode))
                .reduce((sum, category) => sum + category.value, 0);


              currentF4Totals = isNaN(currentF4Totals) ? 0 : Math.abs(currentF4Totals);

              setTotalSellouts(prevTotalSellouts => {
                const existingProductIndex = prevTotalSellouts.findIndex(
                  entry => Object.keys(entry)[0] === product.productName
                );

                if (existingProductIndex !== -1) {
                  // Update existing entry
                  const updatedSellouts = [...prevTotalSellouts];
                  updatedSellouts[existingProductIndex] = {
                    [product.productName]: currentF4Totals
                  };
                  return updatedSellouts;
                } else {
                  // Add new entry
                  return [
                    ...prevTotalSellouts,
                    { [product.productName]: currentF4Totals }
                  ];
                }
              });

              return currentF4Totals;
            })()
            : 0
          : 0
      }
    });

    setRows(dynamicRows);

    setProductList(productAndFormsList.map(item => item.productName));
  }, [productAndFormsList, fuelValues, totalSelloutRelatedForms]);

  useEffect(() => {
    console.log("tsl: " + JSON.stringify(productList, null, 2))
  }, [productList])


  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    console.log("prd;" + JSON.stringify(productAndFormsList, null, 2))

    let containsF4SEU = productAndFormsList[0].forms.some(form => form.parentFormFieldCode === "F4");
    let dynamicHeaders = [];

    if (containsF4SEU) {
      dynamicHeaders = [
        { id: 'no', name: 'No.' },
        { id: 'product', name: 'Product' },
        { id: 'unit', name: 'Unit' },
        { id: 'balance', name: 'Balance' },
        ...productAndFormsList[0].forms.map(form => ({
          id: form.formFieldCode,
          name: `${form.parentFormFieldCode} - ${form.formFieldName}`
        })),

        { id: 'totalSellout', name: 'Total Sell Out' }
      ];
    } else {
      dynamicHeaders = [
        { id: 'no', name: 'No.' },
        { id: 'product', name: 'Product' },
        { id: 'unit', name: 'Unit' },
        { id: 'balance', name: 'Balance' },
        ...productAndFormsList[0].forms.map(form => ({
          id: form.formFieldCode,
          name: `${form.parentFormFieldCode} - ${form.formFieldName}`
        }))
      ];
    }

    setFBHeaders(dynamicHeaders);
  }, [rows]);

  //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
  useEffect(() => {
    if (checkingReport && productAndFormsList.length > 0 && !inputsPopulated) {
      // Iterate through the products in checkingReport
      checkingReport.products.forEach((product) => {
        product.forms.forEach((form) => {
          form.categories.forEach((category) => {
            // NUMERICAL INPUTS
            // Construct the id to match the <input> elements
            const numInputId = `num_${product.productName}_${category.formCode}`;
            const numInputElement = document.getElementById(numInputId);
  
            if (numInputElement) {
              const value = category.value; // Assuming 'value' is directly under category
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              ).set;
              nativeInputValueSetter.call(numInputElement, value);
  
              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('input', { bubbles: true });
              numInputElement.dispatchEvent(event);
            }
  
            // DROPDOWN INPUTS
            // Construct the id to match the <select> elements
            const selectInputId = `selectUnit_${product.productName}`;
            const selectElement = document.getElementById(selectInputId);
  
            if (selectElement) {
              const value = product.unit; // Unit is at the product level
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLSelectElement.prototype,
                'value'
              ).set;
              nativeInputValueSetter.call(selectElement, value);
  
              // Dispatch the 'change' event to trigger onChange in React
              const event = new Event('change', { bubbles: true });
              selectElement.dispatchEvent(event);
            }
          });
        });
      });
  
      // Set inputs as populated
      setInputsPopulated(true);
  
      // Set basic report information
      setReportBasicInfo({
        year: checkingReport.reportPeriodYear,
        quarter: checkingReport.reportQuarter,
        agency: checkingReport.agency.agencyName,
        dataProvider: checkingReport.dataProvider.name,
        status: checkingReport.reportStatus,
        type: "Fuel Balance (Ktoe)"
      });
    }
  }, [checkingReport, fbHeaders]);  

  return (
    <div>
      <div class="table-responsive">
        <table id="fb-ktoe-table" class="table-md table-bordered">
          <thead>
            <tr>
              {fbHeaders.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: 'white',
                        zIndex: 1,

                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust this based on the width of the first column
                          backgroundColor: 'white',
                          zIndex: 1,

                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map(row => (
              <tr key={row.no}>
                {fbHeaders.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout'
                        ? "table-cell-no-width"
                        : "table-cell-minw-200"
                    }
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: 'white',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '199px',  // Adjust based on column width
                            backgroundColor: 'white',
                            zIndex: 1,  // Ensure it's above other cells
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
        <br />
      </div>
      <div className='d-flex justify-content-end'>
        <FontAwesomeIcon title="Download as PDF" style={{ cursor: 'pointer' }} onClick={() => exportToPdf("fb-ktoe-table", reportBasicInfo)} color='#6f6f6f' className='ml-3' size='xl' icon={faCloudDownload} />
      </div>
    </div>
  );
};

export default FuelBalanceTableCheckingKtoeDP;