

import React, { useState, useEffect, useRef } from 'react';
import { Table, Head, Body, Row, Cell } from '@clayui/core';
import { ClaySelect, ClayInput } from '@clayui/form';
import ClayButton from '@clayui/button';
import { getProductAndForms } from '../../../../api/nebProductService';

const PlantPerformanceTable = ({ fetchedReport }) => {

  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [inputsPopulated, setInputsPopulated] = useState(false);

  //API call runs only once, after the initial render
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms('0', 'POW');
        console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
        setProductAndFormsList(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const numInputStyle = {
    textAlign: "right",
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  };

  const selectInputStyle = {
    width: "100%",
    height: "40px",
    marginRight: "5px",
    borderRadius: "5px",
    background: "#f8f8f8",
    color: "black"
  }


  const [fuelValues, setFuelValues] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [productAndUnit, setProductAndUnit] = useState([]);
  const [plantAndFuel, setPlantAndFuel] = useState([]);
  const productNamesRef = useRef(productNames);
  const productAndUnitRef = useRef(productAndUnit);
  const plantAndFuelRef = useRef(plantAndFuel);

  const handleProductNameChange = (index, value) => {
    setProductNames(prevProductNames => {
      const updatedProductNames = [...prevProductNames];
      const productIndex = updatedProductNames.findIndex(p => p.index === index);

      if (productIndex > -1) {
        updatedProductNames[productIndex].productName = value;
      } else {
        updatedProductNames.push({ index, productName: value });
      }

      return updatedProductNames;
    });
  };


  useEffect(() => {
    console.log("productnames: " + JSON.stringify(productNames, null, 2))
    productNamesRef.current = productNames;
  }, [productNames]);

  useEffect(() => {
    console.log("productnames productAndUnit: " + JSON.stringify(productAndUnit, null, 2))
    productAndUnitRef.current = productAndUnit;
  }, [productAndUnit]);

  useEffect(() => {
    console.log("productnames plantAndFuel: " + JSON.stringify(plantAndFuel, null, 2))
    plantAndFuelRef.current = plantAndFuel;
  }, [plantAndFuel]);

  useEffect(() => {
    console.log("productnames fuelValues: " + JSON.stringify(fuelValues, null, 2))
  }, [fuelValues]);

  const handleFuelValueChange = (e, index, parentFormFieldCode, formFieldName) => {
    const { name, value: inputValue } = e.target;
    let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue))

    setFuelValues(prevFuelValues => {
      // Check if an object with matching index exists
      const exists = prevFuelValues.some(item => item.index === index);

      if (exists) {
        // Update the existing object
        return prevFuelValues.map(item => {
          if (item.index === index) {
            // Find the form
            let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
            let updatedForms;

            if (formIndex !== -1) {
              // Update the existing form
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map(category => {
                    if (category.formCode === name) {
                      return {
                        formCode: name,
                        value: value,
                        description: formFieldName
                      };
                    } else {
                      return category;
                    }
                  });

                  // If the category doesn't exist, add it
                  if (!updatedCategories.some(category => category.formCode === name)) {
                    updatedCategories.push({
                      formCode: name,
                      value: value,
                      description: formFieldName
                    });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              // Add a new form
              updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
            }

            // Calculate the new balance
            const balance = updatedForms.reduce((sum, form) => {
              return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
            }, 0);

            return {
              ...item,
              forms: updatedForms,
              balance: isNaN(Number(balance)) ? 0 : Number(balance),
            };
          } else {
            return item;
          }
        });
      } else {
        //find matching plant by index
        const product = productNamesRef.current.find(p => p.index === index);
        console.log("productnames match: " + JSON.stringify(productNamesRef.current, null, 2))
        //find matching plant type by index
        const plantType = productAndUnitRef.current.find(p => p.index === index);
        //find matching fuel by index
        const plantFuel = plantAndFuelRef.current.find(p => p.index === index);
        // Add a new object
        const newItem = {
          index: index,
          balance: isNaN(Number(value)) ? 0 : Number(value),
          productName: product ? product.productName : "-",
          plantType: plantType ? plantType.type : "-",
          fuel: plantFuel ? plantFuel.fuel : "-",
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                { formCode: name, value: value, description: formFieldName }
              ]
            }
          ],
        };

        // Calculate the new balance
        const balance = newItem.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0);

        return [
          ...prevFuelValues,
          { ...newItem, balance: isNaN(Number(balance)) ? 0 : Number(balance) }
        ];
      }
    });
  };

  useEffect(() => {
    console.log("fuel: ", JSON.stringify(fuelValues, null, 2));
  }, [fuelValues]);

  const handleTypeChange = (e, index) => {
    const { value: type } = e.target;
    setProductAndUnit(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.index === index);
      console.log("plantname exists: " + exists)

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          console.log("plantname try matching: " + item.fieldName + ", " + index)
          if (item.index === index) {
            console.log("plantname matched: " + exists)
            return {
              ...item,
              type: type,
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          index: index,
          type: type,
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };

  const handleFuelChange = (e, index) => {
    const { value: fuel } = e.target;

    setPlantAndFuel(prevPlantAndFuel => {
      // Check if an object with matching productName exists
      const exists = prevPlantAndFuel.some(item => item.index === index);

      if (exists) {
        // Update the existing object
        return prevPlantAndFuel.map(item => {
          if (item.index === index) {
            return {
              ...item,
              fuel: fuel,
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          index: index,
          fuel: fuel,
        };

        return [
          ...prevPlantAndFuel,
          newItem
        ];
      }
    });
  };

  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;
    const dynamicRows = productAndFormsList.map((product, index) => {
      return {
        no: index + 1,
        product: <ClayInput
          disabled
          id={`plantNameInput_${index}`}
          type="text"
          onChange={(e) => handleProductNameChange(index, e.target.value)}
        >

        </ClayInput>,
        type: (
          <ClaySelect style={selectInputStyle} disabled id={`selectType_${index}`} onChange={(e) => handleTypeChange(e, index)}>
            <option value=""></option>
            <option value="CCGT">CCGT</option>
            <option value="OCGT">OCGT</option>
            <option value="Thermal">Thermal</option>
            <option value="Conventional Thermal">Conventional Thermal</option>

            {/* {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))} */}
          </ClaySelect>
        ),
        fuel: <ClaySelect style={selectInputStyle} disabled id={`selectFuel_${index}`} onChange={(e) => handleFuelChange(e, index)}>
          <option value=""></option>
          <option value="Hydro">Hydro</option>
          <option value="Mini Hydro">Mini Hydro</option>
          <option value="Gas">Gas</option>
          <option value="Coal">Coal</option>
          <option value="Diesel">Diesel</option>
          {/* {product.units.map((unit, unitIndex) => (
          <option
            key={unitIndex}
            value={unit.productUnitName}
          >
            {unit.productUnitName}
          </option>
        ))} */}
        </ClaySelect>,
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <ClayInput disabled
              id={`num_${index}_${form.formFieldCode}`}
              key={`${index + 1}_${form.formFieldCode}`}
              style={selectInputStyle}
              type="number"
              name={form.formFieldCode}
              onChange={(e) => handleFuelValueChange(e, index, form.parentFormFieldCode, form.formFieldName)}
            />
          );
          return acc;
        }, {}),
      }
    });

    setRows(dynamicRows);
  }, [productAndFormsList, fuelValues]);

  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    const dynamicHeaders = [
      { id: 'no', name: 'No.' },
      { id: 'product', name: 'Name of Power Plant' },
      { id: 'type', name: 'Type of Power Plant' },
      { id: 'fuel', name: 'Fuel' },
      ...productAndFormsList[0].forms.map(form => ({
        id: form.formFieldCode,
        name: `${form.formFieldName}`
      })),

    ];
    setHeaders(dynamicHeaders);
  }, [rows]);


  //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
  useEffect(() => {
    if (
      fetchedReport &&
      Array.isArray(fetchedReport.forms) && // Check if forms exist and is an array
      fetchedReport.forms.length > 0 &&
      productAndFormsList.length > 0 &&
      !inputsPopulated
    ) {
      console.log("received repo: " + JSON.stringify(fetchedReport, null, 2));

      // Iterate through the forms and categories in fetchedData
      fetchedReport.forms.forEach((form) => {
        if (!form) {
          console.warn("Encountered a null or undefined form object, skipping.");
          return; // Skip this form if it's null or undefined
        }

        form.categories.forEach((category) => {
          category.products.forEach((product) => {
            // NUMERICAL INPUTS
            const numInputId = `num_${product.index}_${category.formCode}`;
            const numInputElement = document.getElementById(numInputId);

            if (numInputElement) {
              const value = product.value;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
              nativeInputValueSetter.call(numInputElement, value);

              const event = new Event('input', { bubbles: true });
              numInputElement.dispatchEvent(event);
            }

            // PLANT TYPE DROPDOWN INPUT
            const selectInputId = `selectType_${product.index}`;
            console.log("populating " + selectInputId);
            const selectElement = document.getElementById(selectInputId);

            if (selectElement) {
              const value = product.plantType;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
              nativeInputValueSetter.call(selectElement, value);

              const event = new Event('change', { bubbles: true });
              selectElement.dispatchEvent(event);
            }

            // PLANT FUEL DROPDOWN INPUT
            const selectFuelId = `selectFuel_${product.index}`;
            console.log("populating " + selectFuelId);
            const selectFuelElement = document.getElementById(selectFuelId);

            if (selectFuelElement) {
              const value = product.fuel;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
              nativeInputValueSetter.call(selectFuelElement, value);

              const event = new Event('change', { bubbles: true });
              selectFuelElement.dispatchEvent(event);
            }

            // PLANT NAME INPUT
            const plantNameInputId = `plantNameInput_${product.index}`;
            console.log("populating " + selectInputId);
            const plantNameElement = document.getElementById(plantNameInputId);

            if (plantNameElement) {
              const value = product.fieldName;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
              nativeInputValueSetter.call(plantNameElement, value);

              const event = new Event('input', { bubbles: true });
              plantNameElement.dispatchEvent(event);
            }
          });
        });
      });
      setInputsPopulated(true);
    } else {
      console.warn("fetchedReport.forms is invalid or empty.");
    }
  }, [fetchedReport, productAndFormsList, inputsPopulated]);


  return (
    <div>
      <div class="table-responsive">
        <table class="table-md table-bordered">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: 'white',
                        zIndex: 1,

                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust this based on the width of the first column
                          backgroundColor: 'white',
                          zIndex: 1,

                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map(row => (
              <tr key={row.no}>
                {headers.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' ?
                        "table-cell-no-width"
                        : "table-cell-minw-200"}
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: 'white',
                          zIndex: 1,
                          borderRight: '1px solid #dee2e6'
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '200px',  // Adjust this based on the width of the first column
                            backgroundColor: 'white',
                            zIndex: 1,
                            borderRight: '1px solid #dee2e6'
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
      {/* <ClayButton onClick={addNewRow}>Add More Power Plant</ClayButton > */}
    </div>
  );
}

export default PlantPerformanceTable;


