

import ClayTabs from '@clayui/tabs';
import React, { useEffect, useState } from "react";
import FuelBalanceTable from "./report-tables/FuelBalanceTable";
import C1Table from "./report-tables/C1Table";
import InstalledCapacityTable from "./report-tables/InstalledCapacityTable";
import NetCalorificTable from "./report-tables/NetCalorificTable";
import PlantPerformanceTable from "./report-tables/PlantPerformanceTable";
import { useParams } from "react-router";
import { getParentForms } from '../../../api/nebAgencyFormService';

const DynamicTabs = ({ agencyId, agencyName, fetchedReport, reportMap, setReportMap, selectedQuarter, selectedYear, combinedStructure, setCombinedStructure }) => {
    const [parentForms, setParentForms] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [totalSellouts, setTotalSellouts] = useState([])
    const { paramQuarter, paramYear, reportStatus } = useParams();
    const [lifeId, setLifeId] = useState();
    const [firstTabFound, setFirstTabFound] = useState(false);

    // const nebAgencyService = NebAgencyService();
    // const nebAgencyFormService = NebAgencyFormService();
    // const nebUserAccService = NebUserAccountService();

    //get current session liferay id
    // const getUserAccount = async () => {
    //     try {
    //         const response = await nebUserAccService.getUserAcc();
    //         return response;
    //     } catch (error) {
    //         console.error('Error getting uac:', error);
    //         throw error;
    //     }
    // };

    // useEffect(() => {
    //     const fetchUserAccount = async () => {
    //         try {
    //             const uac = await getUserAccount();
    //             console.log("uacid: " + uac.data.id)
    //             if (uac && uac.data && uac.data.id) {
    //                 setLifeId(uac.data.id);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching user account:', error);
    //         }
    //     };

    //     fetchUserAccount();
    // }, []);

    //get agency id with liferay id
    // const getAgency = async (id) => {
    //     try {
    //         const response = await nebAgencyService.getAgencyByLiferayId(id);
    //         return response;
    //     } catch (error) {
    //         console.error('Error getting agency:', error);
    //         throw error;
    //     }
    // };

    // useEffect(() => {
    //     const fetchAgency = async () => {
    //         if (lifeId) {  // Ensure lifeId is set before fetching agency
    //             try {
    //                 const agency = await getAgency(lifeId);
    //                 setAgencyId(agency);
    //                 console.log("getagency: " + JSON.stringify(agency, null, 2));
    //             } catch (error) {
    //                 console.error('Error fetching agency:', error);
    //             }
    //         }
    //     };

    //     fetchAgency();
    // }, [lifeId]);

    //get forms
    useEffect(() => {
        console.log("dynamic tab here");
        const fetchParentForms = async () => {
            try {
                const result = await getParentForms(agencyId);
                setParentForms(result);
                console.log("res:" + JSON.stringify(result, null, 2))
            } catch (error) {
                console.error('Error fetching parent forms:', error);
            }
        };

        fetchParentForms();
    }, []);

    useEffect(() => {
        if (parentForms.length > 0 && !firstTabFound) {
            setActiveTab(parentForms[0].code)
            setFirstTabFound(true)
        }
    }, [parentForms]);

    const handleSelectTab = (tabKey) => {
        setActiveTab(tabKey);
    };

    const tabDefinitions = {
        "FB": {
            title: "Fuel Balance",
            content: (
                <div style={{ display: activeTab === "FB" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Fuel Balance Report - {paramQuarter} {paramYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <table>
                            <tr>
                                <td>
                                    Agency Name:
                                </td>
                                <td>
                                    {agencyName}
                                </td>
                            </tr>
                        </table>
                        <br />
                        <div style={{ display: 'block' }}>
                            <FuelBalanceTable
                                agencyId={agencyId}
                                agencyName={agencyName}
                                fetchedReport={fetchedReport}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure}
                                totalSellouts={totalSellouts}
                                setTotalSellouts={setTotalSellouts}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        "C1-FECS": {
            title: "Final Energy Consumption Sectors",
            content: (
                <div style={{ display: activeTab === "C1-FECS" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Final Energy Consumption Sectors Report - {paramQuarter} {paramYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <table>
                            <tr>
                                <td>
                                    Agency Name:
                                </td>
                                <td>
                                    {agencyName}
                                </td>
                            </tr>
                        </table>
                        <br />
                        <div style={{ display: 'block' }}>
                            <C1Table
                                agencyId={agencyId}
                                fetchedReport={fetchedReport}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure}
                                totalSellouts={totalSellouts}
                                setTotalSellouts={setTotalSellouts}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        "IC": {
            title: "Installed Capacity",
            content: (
                <div style={{ display: activeTab === "IC" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Installed Capacity Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <InstalledCapacityTable
                                fetchedReport={fetchedReport}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure} />
                        </div>
                    </div>
                </div>
            ),
        },
        "PP": {
            title: "Plant Performance",
            content: (
                <div style={{ display: activeTab === "PP" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Plant Performance Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <PlantPerformanceTable
                                fetchedReport={fetchedReport}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter} selectedYear={selectedYear} reportStatus={reportStatus} combinedStructure={combinedStructure} setCombinedStructure={setCombinedStructure} />
                        </div>
                    </div>
                </div>
            )
        },
        "NC": {
            title: "Net Calorific",
            content: (
                <div style={{ display: activeTab === "NC" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Net Calorific Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <NetCalorificTable
                                fetchedReport={fetchedReport}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure} />
                        </div>
                    </div>
                </div>
            ),
        },

    };

    const renderTabs = () => {
        return parentForms.map((form, index) => {
            const tabDef = tabDefinitions[form.code];
            if (tabDef) {
                return (
                    <ClayTabs.Item
                        key={index}
                        active={activeTab === form.code}
                        onClick={() => handleSelectTab(form.code)}
                    >
                        {form.description}
                    </ClayTabs.Item>
                );
            }
            return null;
        });
    };

    const renderTabContent = () => {
        return parentForms.map((form, index) => {
            const tabDef = tabDefinitions[form.code];
            if (tabDef) {
                return (
                    <div key={index}>
                        {tabDef.content}
                    </div>
                );
            }
            return null;
        });
    };

    return (
        <>
            <ClayTabs>
                {renderTabs()}
            </ClayTabs>
            <ClayTabs.Content>
                {renderTabContent()}
            </ClayTabs.Content>
        </>
    );
};

export default DynamicTabs;


