import axiosInstance from "./axiosInstance";

export const sendUrlToBackend = async (url, createdBy) => {
    try {
        const response = await axiosInstance.post('/url/api/save-url', { url, createdBy });
        console.log('Response from backend:', response.data);
    } catch (error) {
        console.error('Error sending URL to backend:', error);
    }
  };
