import React, { useState, useEffect } from 'react';
import { ClaySelect, ClayInput } from '@clayui/form';
import FuelBalanceChartTabsDP from "../FuelBalanceChartTabsDP";
import ClayButton from '@clayui/button'
import { Modal } from 'react-bootstrap';
import FuelBalanceTableCheckingKtoeDP from './FuelBalanceTableCheckingKtoeDP';
import FuelBalanceTableCheckingDP from './FuelBalanceTableCheckingDP';
import { ClayToggle } from '@clayui/form';
import { getProductAndForms } from "../../../../api/nebProductService";
import { getTotalSelloutRelatedForms } from '../../../../api/nebFormTemplateService';
import "../../../../App.css";

const FuelBalanceTableDP = ({ energySector, viewMode, fetchedReport, updateCheckedFormsCount, agencyId, setFuelValuesFBChecking, setProductAndUnitFBChecking, selectedQuarter, selectedYear, reportStatus, combinedStructure, setCombinedStructure, reportMap, setReportMap, totalSellouts, setTotalSellouts }) => {

  const [fbHeaders, setFBHeaders] = useState([]);
  const [fbRows, setFBRows] = useState([]);
  const [plantRatio, setPlantRatio] = useState(0);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fuelValues, setFuelValues] = useState([]);
  const [productAndUnit, setProductAndUnit] = useState([]);
  const handleShowCheckingModal = () => setShowModal(true);
  const handleCloseCheckingModal = () => {
    console.log("closing checking modal");
    setShowModal(false);
  };
  const [checkingReport, setCheckingReport] = useState();
  const [checkingReportKtoe, setCheckingReportKtoe] = useState();
  const [showKtoe, setShowKtoe] = useState(false);
  const [checked, setChecked] = useState(false);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [totalSelloutRelatedForms, setTotalSelloutRelatedForms] = useState();

  const isDisabled = reportStatus === "Pending for Approval"|| reportStatus === "Approved";

  useEffect(() => {
    const fetchData = async () => {
      if (!agencyId) {
        console.log('agencyId is null or undefined, skipping fetch.');
        return;
      }

      try {
        const result = await getProductAndForms(agencyId, 'FB');
        console.log('FETCHING P&F' + JSON.stringify(result, null, 2));
        setProductAndFormsList(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTotalSelloutRelatedForms = async () => {
      try {
        const result = await getTotalSelloutRelatedForms();
        console.log('FETCHING TSR forms' + JSON.stringify(result, null, 2));
        setTotalSelloutRelatedForms(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    fetchTotalSelloutRelatedForms();
  }, []);

  const inputStyle = {
    textAlign: "right",
    width: "100%",
    height: "30px",
    marginRight: "5px",
    borderRadius: "5px"
  };

  const columnMaxWidthStyle = {
    "min-width": "50px", /* Ensures a minimum width */
    "max-width": "50px", /* Adjust the max-width as needed */
    "overflow": "hidden",
    //"text-overflow": "ellipsis",
    "white-space": "nowrap",
  };

  const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, formCode, subtractive, inKtoe) => {
    const { value: inputValue } = e.target;
    let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue))

    console.log("inktoe: " + inKtoe)

    //Make value negative based on form type
    if (subtractive) {
      value = -Math.abs(value);
    }

    setFuelValues(prevFuelValues => {
      // Check if an object with matching index exists
      const exists = prevFuelValues.some(item => item.index === index);

      if (exists) {
        // Update the existing object
        return prevFuelValues.map(item => {
          if (item.index === index) {
            // Find the form
            let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
            let updatedForms;

            if (formIndex !== -1) {
              // Update the existing form
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map(category => {
                    if (category.formCode === formCode) {
                      return { formCode: formCode, value: value, description: formFieldName };
                    } else {
                      return category;
                    }
                  });

                  // If the category doesn't exist, add it
                  if (!updatedCategories.some(category => category.formCode === formCode)) {
                    updatedCategories.push({ formCode: formCode, value: value, description: formFieldName });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              // Add a new form
              updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: formCode, value: value, description: formFieldName }] }];
            }

            // Calculate the new balance
            const balance = updatedForms.reduce((sum, form) => {
              return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
            }, 0);

            return { ...item, forms: updatedForms, balance: isNaN(Number(balance)) ? 0 : Number(balance), };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          index: index,
          balance: isNaN(Number(value)) ? 0 : Number(value),
          productName: productName,
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                { formCode: formCode, value: value, description: formFieldName }
              ]
            }
          ],
          inKtoe: inKtoe
        };

        // Calculate the new balance
        const balance = newItem.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0);

        return [
          ...prevFuelValues,
          { ...newItem, balance: isNaN(Number(balance)) ? 0 : Number(balance) }
        ];
      }
    });
  };

  //plant efficiency calculation
  useEffect(() => {
    console.log("fuels: " + JSON.stringify(fuelValues, null, 2))
    let f2Sum = 0;
    let f3Sum = 0;

    fuelValues.forEach((fuel) => {
      const product = productAndUnit.find(item => item.fieldName === fuel.productName);
      const inKtoe = product ? product.inKtoe : 0;
      fuel.forms.forEach((form) => {
        form.categories.forEach((category) => {
          if (category.formCode === "F2-PS") {
            // Convert to ktoe and ensure positive value
            f2Sum += Math.abs(category.value * inKtoe);
            console.log(`plantRatio: f2 product: ${JSON.stringify(product, null, 2)}, value: ${category.value}, ktoe: ${inKtoe}`);
          }
          if (category.formCode === "F3-CO") {
            // Convert to ktoe and ensure positive value
            f3Sum += Math.abs(category.value * inKtoe);
            console.log(`plantRatio: f3 product: ${JSON.stringify(product, null, 2)}, value: ${category.value}, ktoe: ${inKtoe}`);
          }
        });
      });
    });

    console.log(`plantRatio: sum: ${f2Sum} / ${f3Sum}`);
    let percent = (f2Sum / f3Sum) * 100;
    console.log(`plantRatio: percent: ${percent}`);

    setPlantRatio(isNaN(Number(percent)) ? 0 : Number(percent.toFixed(2)));
    console.log(" ratio fuel: " + percent);
  }, [fuelValues, productAndUnit])

  useEffect(() => {
    console.log("productAndUnit: ", JSON.stringify(productAndUnit, null, 2));
  }, [productAndUnit]);

  const handleUnitChange = (e, index, productName, inKtoe) => {
    const { value: unit } = e.target;

    setProductAndUnit(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.fieldName === productName);

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          if (item.fieldName === productName) {
            return {
              ...item,
              unit: unit,
              inKtoe: inKtoe
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          fieldName: productName,
          unit: unit,
          inKtoe: inKtoe
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };

  useEffect(() => {
    console.log("combined: ", JSON.stringify(combinedStructure, null, 2));
  }, [combinedStructure]);

  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) { console.log("p&f length 0 here"); return };
    const dynamicRows = productAndFormsList.map((product, index) => {
      const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
      console.log("fobj: " + JSON.stringify(fuelObject, null, 2))
      return {
        no: index + 1,
        product: product.productName,
        balance: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{fuelObject ? fuelObject.balance.toFixed(3) : 0}</span>),
        unit: (
          <ClaySelect
            key={`selectUnit_${product.productName}`}
            id={`draft_fb_selectUnit_${product.productName}`}
            disabled={isDisabled}
            onChange={(e) => handleUnitChange(e, index, product.productName, product.units[e.target.selectedIndex - 1] ? product.units[e.target.selectedIndex - 1].inKtoe : "")}>
            <option value={""}>Select...</option>
            {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        ),
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <ClayInput
              className='no-spinner'
              key={`${index + 1}_${form.formFieldCode}`}
              disabled={isDisabled}
              style={inputStyle}
              type="number"
              id={`draft_fb_num_${product.productName}_${form.formFieldCode}`}
              onChange={(e) => handleFuelValueChange(e, index + 1, product.productName, form.parentFormFieldCode, form.formFieldName, form.formFieldCode, form.subtractive)}
            />
          );
          return acc;
        }, {}),
        totalSellout: productAndFormsList[0].forms.some(form => form.parentFormFieldCode === "F4")
          ? fuelObject ?
            (() => {
              console.log("fuelObject: " + JSON.stringify(fuelObject, null, 2))

              // Ensure totalSelloutRelatedForms is defined
              if (!Array.isArray(totalSelloutRelatedForms)) {
                console.error("totalSelloutRelatedForms is not an array or is undefined:", totalSelloutRelatedForms);
              }

              let currentF4Totals = fuelObject.forms
                .find(form => form.formCode === "F4")
                ?.categories.filter(category => (totalSelloutRelatedForms || []).includes(category.formCode))
                .reduce((sum, category) => sum + (category.value || 0), 0);

              console.log("Current F4 Totals:", currentF4Totals);


              currentF4Totals = isNaN(currentF4Totals) ? 0 : Math.abs(currentF4Totals);

              setTotalSellouts(prevTotalSellouts => {
                const existingProductIndex = prevTotalSellouts.findIndex(
                  entry => Object.keys(entry)[0] === product.productName
                );

                if (existingProductIndex !== -1) {
                  // Update existing entry
                  const updatedSellouts = [...prevTotalSellouts];
                  updatedSellouts[existingProductIndex] = {
                    [product.productName]: currentF4Totals
                  };
                  return updatedSellouts;
                } else {
                  // Add new entry
                  return [
                    ...prevTotalSellouts,
                    { [product.productName]: currentF4Totals }
                  ];
                }
              });

              return currentF4Totals;
            })()
            : 0
          : 0
      }
    });

    setFBRows(dynamicRows);
    console.log("productAndFormsList: " + JSON.stringify(productAndFormsList, null, 2))
    setProductList(productAndFormsList.map(item => item.productName));
    setFormList(productAndFormsList[0].forms.map(item => item.formFieldCode + ": " + item.formFieldName))
  }, [productAndFormsList, fuelValues, totalSelloutRelatedForms]);

  useEffect(() => {
    console.log("totalSellouts: " + JSON.stringify(productAndFormsList, null, 2))
    console.log("formlist: " + JSON.stringify(formList, null, 2))
  }, [productAndFormsList, fuelValues, formList])


  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    console.log("prd;" + JSON.stringify(productAndFormsList, null, 2))
    let containsF4 = productAndFormsList[0].forms.some(form => form.parentFormFieldCode === "F4");
    //let containsF4SEU = productAndFormsList[0].forms.some(form => totalSelloutRelatedForms.includes(form.formFieldCode));
    let dynamicHeaders = [];

    if (containsF4) {
      dynamicHeaders = [
        { id: 'no', name: 'No.' },
        { id: 'product', name: 'Product' },
        { id: 'unit', name: 'Unit' },
        { id: 'balance', name: 'Balance' },
        ...productAndFormsList[0].forms.map(form => ({
          id: form.formFieldCode,
          name: `${form.parentFormFieldCode} - ${form.formFieldName}`
        })),

        { id: 'totalSellout', name: 'Total Sell Out' }
      ];
    } else {
      dynamicHeaders = [
        { id: 'no', name: 'No.' },
        { id: 'product', name: 'Product' },
        { id: 'unit', name: 'Unit' },
        { id: 'balance', name: 'Balance' },
        ...productAndFormsList[0].forms.map(form => ({
          id: form.formFieldCode,
          name: `${form.parentFormFieldCode} - ${form.formFieldName}`
        }))
      ];
    }

    setFBHeaders(dynamicHeaders);
  }, [fbRows]);

  useEffect(() => {
    console.log("fbheaders: " + JSON.stringify(fbHeaders, null, 2))
  }, [fbHeaders])

  useEffect(() => {
    const productsMap = {};
    console.log("fuelValues:", JSON.stringify(fuelValues, null, 2));

    fuelValues.forEach((fuelItem) => {
      // Ensure the product entry exists in productsMap
      if (!productsMap[fuelItem.productName]) {
        productsMap[fuelItem.productName] = {
          productName: fuelItem.productName,
          unit: productAndUnit.find((item) => item.fieldName === fuelItem.productName)?.unit || "", // Unit is at the product level
          forms: []
        };
      }

      fuelItem.forms.forEach((form) => {
        // Check if the form already exists for the product
        const existingFormIndex = productsMap[fuelItem.productName].forms.findIndex(
          (existingForm) => existingForm.formCode === form.formCode
        );

        if (existingFormIndex === -1) {
          // Add a new form if it doesn't exist
          productsMap[fuelItem.productName].forms.push({
            formCode: form.formCode,
            categories: []
          });
        }

        // Get the target form
        const targetForm = productsMap[fuelItem.productName].forms.find(
          (f) => f.formCode === form.formCode
        );

        form.categories.forEach((category) => {
          const categoryIndex = targetForm.categories.findIndex(
            (cat) => cat.formCode === category.formCode
          );

          if (categoryIndex !== -1) {
            // Update the existing category with the value
            targetForm.categories[categoryIndex].value = Math.abs(category.value); // Ensure value is positive
            targetForm.categories[categoryIndex].description = category.description;
          } else {
            // Add a new category
            targetForm.categories.push({
              formCode: category.formCode,
              description: category.description,
              value: Math.abs(category.value) // Ensure value is positive
            });
          }
        });
      });
    });

    const productsArray = Object.values(productsMap);

    console.log("Updated Products Array:", JSON.stringify(productsArray, null, 2));

    setReportMap((prevState) => ({
      ...prevState,
      reportPeriodYear: selectedYear,
      reportQuarter: selectedQuarter,
      products: [
        // Fallback to an empty array if products is undefined
        ...(prevState.products || []).filter(
          (product) =>
            !productsArray.some((newProduct) => newProduct.productName === product.productName)
        ),
        // Add the updated products
        ...productsArray
      ]
    }));
  }, [fuelValues, productAndUnit, selectedQuarter, selectedYear]);

  useEffect(() => {
    setCombinedStructure(reportMap);
  }, [reportMap]);

  useEffect(() => {
    const ktoeReport = convertToKtoe(combinedStructure);
    setCheckingReport(combinedStructure); // Original report
    setCheckingReportKtoe(ktoeReport);    // ktoe-converted report
  }, [combinedStructure]);

  function convertToKtoe(report) {
    if (!report?.products || !Array.isArray(report.products)) return report;

    // Deep clone the report to avoid mutating the original state
    const updatedReport = {
      ...report,
      products: report.products.map((product) => {
        const matchingProduct = productAndFormsList.find(
          (p) => p.productName === product.productName
        );

        if (matchingProduct) {
          const matchingUnit = matchingProduct.units.find(
            (unit) =>
              unit.productUnitName === product.unit || unit.productUnitName === "ktoe"
          );

          if (matchingUnit?.inKtoe !== undefined) {
            return {
              ...product,
              unit: "ktoe",
              forms: product.forms.map((form) => ({
                ...form,
                categories: form.categories.map((category) => ({
                  ...category,
                  value: parseFloat((category.value * matchingUnit.inKtoe).toFixed(4)), // Limit to 4 decimal points
                })),
              })),
            };
          } else {
            console.error(
              `Matching unit or ktoe conversion value not found for product: ${product.productName}`
            );
          }
        }
        return { ...product }; // Return the original product unchanged if no match found
      }),
    };

    console.log("Final converted report:", JSON.stringify(updatedReport, null, 2));
    return updatedReport;
  }


  //check if form is balanced and have no missing unit
  useEffect(() => {
    setFuelValuesFBChecking(fuelValues);
  }, [fuelValues])

  useEffect(() => {
    setProductAndUnitFBChecking(productAndUnit);
  }, [productAndUnit])

  useEffect(() => {
    console.log("mystery fbh" + JSON.stringify(fbHeaders, null, 2));
    console.log("mystery fbr" + JSON.stringify(fbRows, null, 2));
  }, [fbHeaders, fbRows]);

  //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
  /*useEffect(() => {
    console.log("fetched Rerpot :",JSON.stringify(fetchedReport));
    if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated && viewMode === "draft") {
      // Iterate through the forms and categories in fetchedData
      fetchedReport.forms.forEach((form) => {
        form.categories.forEach((category) => {
          category.products.forEach((product) => {

            // NUMERICAL INPUTS
            // Construct the id to match the <input> elements
            const numInputId = `draft_fb_num_${product.fieldName}_${category.formCode}`;
            const numInputElement = document.getElementById(numInputId);
            //console.log("debugc1: numInputId" + numInputId)


            if (numInputElement) {
              const value = product.value;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
              nativeInputValueSetter.call(numInputElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('input', { bubbles: true });
              numInputElement.dispatchEvent(event);
            }

            // DROPDOWN INPUTS
            // Construct the id to match the <select> elements
            const selectInputId = `draft_fb_selectUnit_${product.fieldName}`;
            const selectElement = document.getElementById(selectInputId);

            if (selectElement) {
              const value = product.unit;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
              nativeInputValueSetter.call(selectElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('change', { bubbles: true });
              selectElement.dispatchEvent(event);
            }
          });
        });
      });
      setInputsPopulated(true);
    }
    //console.log("fetchedre: "+JSON.stringify(fetchedReport, null, 2))
  }, [fetchedReport, fbHeaders]);*/
  useEffect(() => {
    console.log("Fetched Report:", JSON.stringify(fetchedReport));

    if (
      fetchedReport &&
      Array.isArray(fetchedReport.products) && // Ensure products is an array
      productAndFormsList.length > 0 &&
      !inputsPopulated &&
      viewMode === "draft"
    ) {
      // Iterate through the products in fetchedData
      fetchedReport.products.forEach((product) => {
        console.log("Processing Product:", JSON.stringify(product));
        // DROPDOWN INPUTS
        const selectInputId = `draft_fb_selectUnit_${product.productName}`;
        const selectElement = document.getElementById(selectInputId);

        if (selectElement) {
          const value = product.unit;
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLSelectElement.prototype,
            "value"
          ).set;
          nativeInputValueSetter.call(selectElement, value);

          // Dispatch the 'change' event to trigger onChange in React
          const event = new Event("change", { bubbles: true });
          selectElement.dispatchEvent(event);
        }

        // Ensure forms exist and is an array
        if (Array.isArray(product.forms)) {
          product.forms.forEach((form) => {
            console.log("Processing Form:", JSON.stringify(form));

            // Ensure categories exist and is an array
            if (Array.isArray(form.categories)) {
              form.categories.forEach((category) => {
                console.log("Processing Category:", JSON.stringify(category));

                // NUMERICAL INPUTS
                const numInputId = `draft_fb_num_${product.productName}_${category.formCode}`;
                console.log("numInputId ", numInputId);
                const numInputElement = document.getElementById(numInputId);

                if (numInputElement) {
                  const value = category.value;
                  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                    window.HTMLInputElement.prototype,
                    "value"
                  ).set;
                  nativeInputValueSetter.call(numInputElement, value);

                  // Dispatch the 'input' event to trigger onChange in React
                  const event = new Event("input", { bubbles: true });
                  numInputElement.dispatchEvent(event);
                }

              });
            } else {
              console.warn("Form categories is not an array or missing:", form);
            }
          });
        } else {
          console.warn("Product forms is not an array or missing:", product);
        }
      });

      setInputsPopulated(true);
    }
  }, [fetchedReport, fbHeaders]);


  return (
    <div>
      <div class="table-responsive">
        <table class="table-md table-bordered">
          <thead>
            <tr>
              {fbHeaders.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: 'white',
                        zIndex: 1,

                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust this based on the width of the first column
                          backgroundColor: 'white',
                          zIndex: 1,

                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {fbRows.map(row => (
              <tr key={row.no}>
                {fbHeaders.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout'
                        ? "table-cell-no-width"
                        : "table-cell-minw-200"
                    }
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: 'white',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '199px',  // Adjust based on column width
                            backgroundColor: 'white',
                            zIndex: 1,  // Ensure it's above other cells
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
        <br />
      </div>
      <div className="text-center mt-5 mb-3 d-flex justify-content-end"
      ><ClayButton onClick={handleShowCheckingModal}>Check</ClayButton >
      </div>

      <FuelBalanceChartTabsDP energySector={energySector} plantRatio={plantRatio} selectedYear={selectedYear} selectedQuarter={selectedQuarter} productList={productList} formList={formList} productAndFormsList={productAndFormsList} agencyId={agencyId} />

      <Modal size='xl' show={showModal} onHide={handleCloseCheckingModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <span>Check your data before submitting [agencyName] {selectedQuarter} {selectedYear} report.</span> */}
          <div>
            <span>Check all numbers, balances or units in your Fuel Balance. </span>
            <div className="d-flex justify-content-end">
              <table>
                <tr>
                  <div className='d-flex justify-content-end'>
                    <td><span className='mr-2'>Original unit</span></td>
                    <td>
                      <ClayToggle
                        //label="In ktoe"
                        onToggle={(newVal) => setShowKtoe(newVal)} // Explicitly pass the new toggle state
                        toggled={showKtoe}
                      />
                    </td>
                    <td><span>In ktoe</span></td>
                  </div>

                </tr>
              </table>
            </div>
            {
              !showKtoe ? (
                <FuelBalanceTableCheckingDP
                  updateCheckedFormsCount={updateCheckedFormsCount}
                  agencyId={agencyId}
                  productAndFormsList={productAndFormsList}
                  checkingReport={checkingReport}
                  setTotalSellouts={setTotalSellouts}
                />
              ) : (
                <FuelBalanceTableCheckingKtoeDP
                  agencyId={agencyId}
                  productAndFormsList={productAndFormsList}
                  checkingReport={checkingReportKtoe}
                  setCheckingReport={setCheckingReportKtoe}
                  reportMap={reportMap}
                  setReportMap={setReportMap}
                  selectedQuarter={selectedQuarter}
                  selectedYear={selectedYear}
                  reportStatus={reportStatus}
                  combinedStructure={combinedStructure}
                  setCombinedStructure={setCombinedStructure}
                  totalSellouts={totalSellouts}
                  setTotalSellouts={setTotalSellouts}
                />
              )
            }
          </div>


        </Modal.Body>
        <Modal.Footer>
          {/* <button className="btn btn-outline-secondary mr-1" onClick={handleCloseCheckingModal}>
            Close
          </button>
          <button
                onClick={() => submitReport("Draft")}
                className="btn btn-outline-primary mr-1"
              >
                Save As Draft
              </button>
          <button className="btn btn-primary mr-1" onClick={handleShowConfirmationModal}>
            Submit
          </button> */}

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FuelBalanceTableDP;
