import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { ClaySelect } from '@clayui/form';
import { getDTAResult } from '../../../../api/nebReportService';

const DataTrendAnalysisDP = ({ selectedYear, selectedQuarter, productList, formList, productAndFormsList, agencyId }) => {
  const [categories, setCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [series, setSeries] = useState([]);
  const [dtaFetchResult, setDTAFetchResult] = useState({});
  // const []

  useEffect(() => {
    if (productList) {
      setSelectedProduct(productList[0]);
      setSelectedForm(formList[0]);
    }
    console.log("fb dta: " + JSON.stringify(productAndFormsList, null, 2))
  }, [productList, formList]);

  // UseEffect to generate dummy data
  useEffect(() => {
    const fetchProductStats = async () => {
      try {
        const result = await getDTAResult(selectedQuarter, selectedYear, selectedProduct, selectedForm.split(":")[0].trim(), agencyId)
        console.log("dta result: " + selectedQuarter + selectedYear + "\n" + JSON.stringify(result.data, null, 2));
        setDTAFetchResult(result.data);
      } catch (error) {
        console.error('Error generating dummy data:', error);
      }
    };

    fetchProductStats();
  }, [selectedQuarter, selectedYear, selectedProduct, selectedForm]);

  /* useEffect(() => {
    const years = new Set(); // To collect unique years
    const values = {}; // To collect values for each year
    const valuesWithUnit = {};


    if (dtaFetchResult && dtaFetchResult.length > 0) {
      console.log("dta result 2: 1 " + selectedQuarter + selectedYear);
      dtaFetchResult.forEach(report => {
        const dtaFetchReportKtoe = convertToKtoe(report);
        const year = dtaFetchReportKtoe.reportPeriodYear;
        // Check if the report contains the selected form and product
        dtaFetchReportKtoe.forms.forEach(form => {
          if (form) {
            if (form.categories && form.categories.length > 0) {
              form.categories.forEach(category => {
                if (category.formCode === selectedForm.split(":")[0].trim()) {
                  const product = category.products.find(p => p.fieldName === selectedProduct);
                  if (product) {
                    // Update values for this year
                    if (!values[year]) {
                      values[year] = product.value;
                      valuesWithUnit[year] = {
                        "name": product.fieldName,
                        "value": product.value,
                        "unit": product.unit
                      };
                    } else {
                      values[year] += product.value; // Sum values for the same year if needed
                    }
                    years.add(year); // Add the year to the set
                  }
                }
              });
            }
          }
        });
      });


      // Convert the Set to an array to use as x-axis labels
      const xAxisLabels = Array.from(years).sort(); // Sort the years if needed
      console.log("dta values: " + JSON.stringify(values, null, 2))
      console.log("dta values with unit: " + JSON.stringify(valuesWithUnit, null, 2))
      const series = xAxisLabels.map(year => values[year] || 0); // Get the values for each year
      
      // Set the seriesData for Highcharts
      setSeries([
        {
          name: selectedProduct,
          data: series
        }
      ]);

      setCategories(xAxisLabels);
    } else {
      setSeries([]);
      setCategories([]);
    }
  }, [dtaFetchResult]); */

  useEffect(() => {
    const years = new Set(); // To collect unique years
    const values = {}; // To store summed values for each year
    const valuesWithUnit = {}; // To store detailed values with units for each year

    if (dtaFetchResult && dtaFetchResult.length > 0) {
      console.log(`dta result: ${selectedQuarter} ${selectedYear}`);
      console.log("dta ", JSON.stringify(dtaFetchResult));
      dtaFetchResult.forEach((report) => {
        const dtaFetchReportKtoe = convertToKtoe(report);
        const year = dtaFetchReportKtoe.reportPeriodYear;

        if (Array.isArray(dtaFetchReportKtoe.products)) {
          dtaFetchReportKtoe.products.forEach((product) => {
            if (Array.isArray(product.forms)) {
              product.forms.forEach((form) => {
                if (Array.isArray(form.categories)) {
                  form.categories.forEach((category) => {
                    if (category.formCode === selectedForm.split(":")[0].trim()) {
                      if (product.productName == selectedProduct) {
                        const matchingProduct = category;
                        if (matchingProduct) {
                          // Update yearly values
                          if (!values[year]) {
                            values[year] = matchingProduct.value;
                            valuesWithUnit[year] = {
                              name: matchingProduct.formCode,
                              value: matchingProduct.value,
                              unit: matchingProduct.unit,
                            };
                          } else {
                            values[year] += matchingProduct.value; // Aggregate values for the same year
                          }
                          years.add(year); // Track the year
                        }
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });

      // Create x-axis labels and series data
      const xAxisLabels = Array.from(years).sort(); // Sort years for logical ordering
      console.log("dta values:", JSON.stringify(values, null, 2));
      console.log("dta values with unit:", JSON.stringify(valuesWithUnit, null, 2));

      const series = xAxisLabels.map((year) => values[year] || 0); // Extract or default to 0

      // Update chart data
      setSeries([
        {
          name: selectedProduct,
          data: series,
        },
      ]);

      setCategories(xAxisLabels);
    } else {
      // Clear chart data if no results
      setSeries([]);
      setCategories([]);
    }
  }, [dtaFetchResult]);


  useEffect(() => {
    console.log("dta series: " + JSON.stringify(series, null, 2));
    console.log("dta series: cat: " + JSON.stringify(categories, null, 2));
    if (series.length < 0) {

    }
  }, [series, categories]);

  // Highcharts options
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: `${selectedProduct} comparison for ${selectedForm} in ${selectedQuarter} (ktoe)`,
    },
    xAxis: {
      categories: categories,
      title: {
        text: "",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'ktoe',
      },
    },
    series: series,
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false, // Disables the legend
    },
  };

  const handleProductSelectChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleSelectedFormChange = (event) => {
    setSelectedForm(event.target.value);
  };

  function convertToKtoe(report) {
    if (!Array.isArray(report?.products)) {
      console.error("Invalid report structure: Missing 'products'.");
      return report; // Return the original report if it doesn't contain 'products'
    }

    const updatedReport = {
      ...report,
      products: report.products.map((product) => {
        const matchingProduct = productAndFormsList.find(
          (p) => p.productName === product.productName
        );

        if (!matchingProduct) {
          console.warn(`No matching product found for ${product.productName}`);
          return product; // Return original product if no match
        }

        return {
          ...product,
          forms: product.forms.map((form) => ({
            ...form,
            categories: form.categories.map((category) => {
              const ktoeValue = matchingProduct.units.find(
                (unit) =>
                  unit.productUnitName === product.unit ||
                  unit.productUnitName === "ktoe"
              )?.inKtoe;

              if (ktoeValue !== undefined) {
                return {
                  ...category,
                  value: parseFloat((category.value * ktoeValue).toFixed(4)),
                  unit: "ktoe", // Update the category's unit
                };
              } else {
                console.warn(
                  `No ktoe conversion available for category: ${category.formCode} in product: ${product.productName}`
                );
                return { ...category }; // Return original category if no ktoe conversion
              }
            }),
          })),
        };
      }),
    };

    return updatedReport;
  }





  return (
    <div>
      <div className="row mb-4">
        <div className="col-sm-6">
          <p>Product: </p>
          <ClaySelect id="product" value={selectedProduct} onChange={handleProductSelectChange}>
            <option value="" disabled>Select Product...</option>
            {productList.map((product, index) => (
              <option key={index} value={product}>
                {product}
              </option>
            ))}
          </ClaySelect>
        </div>
        <div className="col-sm-6">
          <p>Form: </p>
          <ClaySelect id="form" value={selectedForm} onChange={handleSelectedFormChange}>
            <option value="" disabled>Select Form...</option>
            {formList.map((form, index) => (
              <option key={index} value={form}>
                {form}
              </option>
            ))}
          </ClaySelect>
        </div>
      </div>
      <br />
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div style={{ textAlign: 'center' }}>{series && series.length === 0 ? <i style={{ color: '#9a9a9a' }}>Data is not available yet. <br /> Kindly select other quarter, product or form combinations.</i> : null}</div>

    </div>
  );
};

export default DataTrendAnalysisDP;
