import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from 'react-bootstrap';

const YearComparisonChart = ({ selectedYear, comparisonYear, reports, reportsToCompare }) => {
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        if (!reports || !reportsToCompare || !selectedYear || !comparisonYear) return;

        // Helper function to count reports by year
        const countReportsByYear = (data) =>
            data.length;

        // Counts for the main reports
        const selectedYearCountReports = countReportsByYear(reports);
        const comparisonYearCountReports = countReportsByYear(reportsToCompare);

        setChartOptions({
            chart: {
                type: 'column',
            },
            title: {
                text: `Year Comparison Chart (${selectedYear} vs ${comparisonYear})`,
            },
            xAxis: {
                categories: [selectedYear, comparisonYear],
                title: {
                    text: 'Years',
                },
            },
            yAxis: {
                title: {
                    text: 'Report Submission',
                },
            },
            series: [
                {
                    name: selectedYear,
                    data: [selectedYearCountReports],
                },
                {
                    name: comparisonYear,
                    data: [comparisonYearCountReports],
                },
            ],
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>',
            },
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
        });
    }, [reports, reportsToCompare, selectedYear, comparisonYear]);

    return (
        <Card
            className="mb-5 p-5"
            style={{ boxShadow: '0px 0px 0px 0px', border: '2px solid #dcdcdc', borderRadius: '10px' }}
        >
            {chartOptions.series && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
        </Card>
    );
};

export default YearComparisonChart;