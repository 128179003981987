import React, { useState, useEffect } from 'react';
import { Table, Head, Body, Row, Cell } from '@clayui/core';
import { ClaySelect, ClayInput } from '@clayui/form';
import ClayButton from '@clayui/button'
import { Modal } from 'react-bootstrap';
import InstalledCapacityTableCheckingDP from './InstalledCapacityTableCheckingDP';
import { ClayToggle } from '@clayui/form';
import { getProductAndForms } from "../../../../api/nebProductService";
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import exportToPdf from './exportToPdf';
import "../../../../App.css";



const InstalledCapacityTableDP = ({ viewMode, fetchedReport, updateCheckedFormsCount, selectedQuarter, selectedYear, setCombinedStructure, reportMap, setReportMap,reportStatus }) => {

    const isDisabled = reportStatus === "Pending for Approval"|| reportStatus === "Approved";
    
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [productAndFormsList, setProductList] = useState([]);
    let otherSetProductName = "";
    const handleShowCheckingModal = () => {
        setShowModal(true)
    };
    const handleCloseCheckingModal = () => {
        console.log("closing checking modal");
        setShowModal(false);
    };
    const [checkingReport, setCheckingReport] = useState();
    const [checkingReportKtoe, setCheckingReportKtoe] = useState();
    const [showKtoe, setShowKtoe] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [inputsPopulated, setInputsPopulated] = useState(false);
    const [totals, setTotals] = useState({
        "IC-TIC": 0,
        "IC-TAC": 0,
        "IC-PEA": 0
    }
    );
    const [otherSetName, setOtherSetName] = useState("");
    const [otherSetKeyedIn, setOtherSetKeyedIn] = useState(false);
    const [reportBasicInfo, setReportBasicInfo] = useState();


    //API call runs only once, after the initial render
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms('0', 'IC');
                setProductList(result);
                console.log('ic: ' + JSON.stringify(result, null, 2))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const inputStyle = {
        textAlign: "right",
        width: "100%",
        height: "30px",
        marginRight: "5px",
        borderRadius: "5px"
    };


    const [fuelValues, setFuelValues] = useState([]);

    const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, formFieldCode, subtractive) => {
        console.log("icchange: " + index + ", " + productName + ", " + parentFormFieldCode + ", " + formFieldName, + ", " + formFieldCode)
        const { name, value: inputValue } = e.target;
        let value = isNaN(parseFloat(inputValue)) ? 0 : parseFloat(inputValue);

        console.log("ofs: current value: " + value);

        //Make value negative based on form type
        if (subtractive) {
            value = -Math.abs(value);
        }

        //if other sets, allow to add name
        if (productName === "Other Sets") {
            setOtherSetKeyedIn(true);
        }

        setFuelValues(prevFuelValues => {
            // Check if an object with matching formFieldCode exists
            const exists = prevFuelValues.some(item => item.productName === productName);

            if (exists) {
                // Update the existing object
                return prevFuelValues.map(item => {
                    if (item.index === index) {
                        // Find the form
                        let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
                        let updatedForms;

                        if (formIndex !== -1) {
                            // Update the existing form
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map(category => {
                                        if (category.formCode === name) {
                                            return { formCode: name, value: value === null ? 0 : value, description: formFieldName };
                                        } else {
                                            return category;
                                        }
                                    });

                                    // If the category doesn't exist, add it
                                    if (!updatedCategories.some(category => category.formCode === name)) {
                                        updatedCategories.push({ formCode: name, value: value === null ? 0 : value, description: formFieldName });
                                    }

                                    return { ...form, categories: updatedCategories };
                                } else {
                                    return form;
                                }
                            });
                        } else {
                            // Add a new form
                            updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value === null ? 0 : value, description: formFieldName }] }];
                        }

                        // Calculate the new balance
                        const balance = updatedForms.reduce((sum, form) => {
                            return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                        }, 0);

                        return { ...item, forms: updatedForms, balance: balance };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    index: index,
                    balance: value,
                    productName: productName,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                { formCode: name, value: value === null ? 0 : value, description: formFieldName }
                            ]
                        }
                    ]
                };

                // Calculate the new balance
                const balance = newItem.forms.reduce((sum, form) => {
                    return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                }, 0);

                return [
                    ...prevFuelValues,
                    { ...newItem, balance: balance }
                ];
            }
        });
    };


    //calculate totals as input elements' value changes
    useEffect(() => {
        console.log("icfuel: ", JSON.stringify(fuelValues, null, 2));

        let _totalInstalledCapacitySum = 0;
        let _totalAvailableCapacitySum = 0;
        let _peakDemandSum = 0;

        fuelValues.forEach((product) => {
            product.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    if (category.formCode === "IC-TIC") {
                        _totalInstalledCapacitySum += category.value;
                    } else if (category.formCode === "IC-TAC") {
                        _totalAvailableCapacitySum += category.value;
                    } else if (category.formCode === "IC-PEA") {
                        _peakDemandSum += category.value;
                    }
                });
            });
        });

        setTotals({
            "IC-TIC": _totalInstalledCapacitySum,
            "IC-TAC": _totalAvailableCapacitySum,
            "IC-PEA": _peakDemandSum
        }
        )
    }, [fuelValues]);

    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    const handleOtherSetNameChange = (e) => {
        let value = e.target.value;
        setOtherSetName(value);
    }

    useEffect(() => {
        console.log("othersetname: " + otherSetName);
        // Loop through the forms array
        if (reportMap.forms && reportMap.forms.length > 0) {
            const updatedReportMap = {
                ...reportMap,
                forms: reportMap.forms.map((form) => ({
                    ...form,
                    categories: form.categories.map((category) => ({
                        ...category,
                        products: category.products.map((product) =>
                            product.fieldName.includes("Other Sets")
                                ? { ...product, fieldName: `${otherSetName},Other Sets` }
                                : product
                        )
                    }))
                }))
            };

            // Update the state with the new reportMap
            setReportMap(updatedReportMap);
        }
    }, [otherSetName])

    useEffect(() => {
        console.log("debug Updated productAndUnit: ", JSON.stringify(productAndUnit, null, 2));
    }, [productAndUnit]);

    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        // Group products by their category (split by comma)
        const groupedProducts = productAndFormsList.reduce((acc, product) => {
            const [productType, category] = product.productName.split(',');
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(product);
            return acc;
        }, {});

        const dynamicRows = [];
        let categoryIndex = 1;
        let productIndex = 1;

        // Iterate over the grouped products by category
        for (const category in groupedProducts) {
            // Add a row for the category with colSpan extended to cover all columns
            dynamicRows.push({
                //no: categoryIndex,
                category:
                    (
                        <span>
                            <strong>{categoryIndex}. {category}</strong>
                        </span>
                    )
            });

            // Add the rows for each product under the current category
            groupedProducts[category].forEach((product, index) => {
                const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
                dynamicRows.push({
                    no: index + 1,
                    product: product.productName.split(',')[0], // Display only the product name
                    ...product.forms.reduce((acc, form) => {
                        //need only one peak demand input, not for every product/row

                        acc[form.formFieldCode] = (
                            <ClayInput
                                disabled = {isDisabled}
                                className='no-spinner'
                                key={`${index + 1}_${form.formFieldCode}`}
                                id={`num_ic_draft_${product.productName}_${form.formFieldCode}`}
                                style={inputStyle}
                                type="number"
                                name={form.formFieldCode}
                                onChange={(e) => handleFuelValueChange(e, product.productName, product.productName, form.parentFormFieldCode, form.formFieldName, form.formFieldCod, form.subtractive)}
                            />
                        );


                        return acc;
                    }, {}),
                    balance: (
                        <span style={{ paddingRight: "10px", display: "block", textAlign: "right" }}>
                            {fuelObject ? fuelObject.balance : 0}
                        </span>
                    ) // Handle undefined case
                });
            });

            categoryIndex++; // Increment category number
        }

        //Add "Other Sets" as the last category
        dynamicRows.push({
            no: categoryIndex,
            category: (
                <span>
                    <strong>{categoryIndex}. Other Sets</strong>
                </span>
            )
        });

        // Add one child product under "Other Sets" with a text ClayInput for the product name
        const otherForms = productAndFormsList[0].forms; // Assuming "Other Sets" has the same form structure as other products

        dynamicRows.push({
            no: categoryIndex + 1,
            product: (
                <ClayInput
                    id={`name_other_sets_ic}`}
                    type="text"
                    //disabled={!otherSetKeyedIn}
                    //onChange={(e) => handleOtherSetNameChange(e)}
                    disabled={true}
                    value="Other Set"
                />
            ),
            unit: '',
            ...otherForms.reduce((acc, form) => {
                acc[form.formFieldCode] = (
                    <ClayInput
                        className='no-spinner'
                        disabled = {isDisabled}
                        key={`other_${form.formFieldCode}`}
                        id={`num_other_ic_${form.formFieldCode}`}
                        placeholder=''
                        style={inputStyle}
                        type="number"
                        name={form.formFieldCode}
                        onChange={(e) => handleFuelValueChange(e, categoryIndex, "Other Sets", form.parentFormFieldCode, form.formFieldName, form.subtractive)}
                    />
                );
                return acc;
            }, {}),
            balance: ''
        });

        console.log("ofs: " + JSON.stringify(fuelValues, null, 2))


        //add "TOTAL" row
        dynamicRows.push({
            no: categoryIndex + 1,
            product: (
                <span>
                    <strong>TOTAL</strong>
                </span>
            ),
            unit: '',
            ...otherForms.reduce((acc, form) => {
                console.log(`total_${form.formFieldCode}: ${totals[form.formFieldCode]}`)
                acc[form.formFieldCode] = (
                    <div style={{ textAlign: 'center' }}> {/* Centering the text */}
                        <strong>{isNaN(Number(totals[form.formFieldCode])) ? 0 : Number(totals[form.formFieldCode])}</strong>
                    </div>
                    // <ClayInput
                    //     key={`total_${form.formFieldCode}`}
                    //     style={inputStyle}
                    //     type="number"
                    //     disabled={true}
                    //     value={totals[form.formFieldCode]}
                    //     name={`total_${form.formFieldCode}`}
                    // />
                );
                //console.log("acc: " + JSON.stringify(acc, null, 2))
                return acc;
            }, {}),
            balance: '' // Empty balance (if needed)
        });

        console.log("dyr: " + JSON.stringify(otherForms, null, 2));


        setRows(dynamicRows);
    }, [totals, productAndFormsList, fuelValues]);

    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            //{ id: 'no', name: 'No.' },
            { id: 'product', name: 'Types of Prime Mover' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName}`
            }))
        ];
        setHeaders(dynamicHeaders);
        console.log("hello: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    //merge

    // const [combinedStructure, setCombinedStructure] = useState(null);

    useEffect(() => {
        const formsMap = {};

        fuelValues.forEach(fuelItem => {
            fuelItem.forms.forEach(form => {
                if (!formsMap[form.formCode]) {
                    formsMap[form.formCode] = {
                        formCode: form.formCode,
                        categories: []
                    };
                }

                form.categories.forEach(category => {
                    const product = {
                        fieldName: fuelItem.productName,
                        value: Math.abs(category.value), // ensure always positive
                        unit: productAndUnit.find(item => item.fieldName === fuelItem.productName)?.unit || 'MW'
                    };

                    const categoryIndex = formsMap[form.formCode].categories.findIndex(cat => cat.formCode === category.formCode);

                    if (categoryIndex !== -1) {
                        formsMap[form.formCode].categories[categoryIndex].products.push(product);
                    } else {
                        formsMap[form.formCode].categories.push({
                            formCode: category.formCode,
                            description: category.description,
                            products: [product],
                            total: totals[category.formCode]
                        });
                    }
                });
            });
        });

        const formsArray = Object.values(formsMap);

        console.log("fb cat, reportMap.forms : ", JSON.stringify(...reportMap.forms, null, 2));
        console.log("fb cat, formsArray : ", JSON.stringify(...formsArray, null, 2));

        //reportMap.forms = [...reportMap.forms, ...formsArray];
        //setCombinedStructure(reportMap);

        setReportMap(prevState => ({
            ...prevState,
            reportPeriodYear: selectedYear,
            reportQuarter: selectedQuarter,
            forms: [
                // Remove existing forms with the same formCode
                ...prevState.forms.filter(form => !formsArray.some(newForm => newForm.formCode === form.formCode)),
                // Add the updated forms
                ...formsArray
            ]
        }));
    }, [totals, fuelValues, productAndUnit, selectedQuarter, selectedYear]);

    useEffect(() => {
        setCombinedStructure(reportMap);
        setCheckingReport(reportMap);
        //Get report basic info
        setReportBasicInfo(
            {
                "year": reportMap.reportPeriodYear,
                "quarter": reportMap.reportQuarter,
                "agency": reportMap.agency.agencyName,
                "dataProvider": reportMap.dataProvider.name,
                "status": reportMap.reportStatus,
                "type": "Installed Capacity"
            }
        )
    }, [reportMap]);

    //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
    useEffect(() => {
        if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated && viewMode === "draft") {
            console.log("received ic: " + JSON.stringify(fetchedReport, null, 2))
            // Iterate through the forms and categories in fetchedData
            fetchedReport.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    category.products.forEach((product) => {

                        // NUMERICAL INPUTS
                        // Construct the id to match the <input> elements
                        const numInputId = `num_ic_draft_${product.fieldName}_${category.formCode}`;
                        const numInputElement = document.getElementById(numInputId);

                        if (numInputElement) {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(numInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            numInputElement.dispatchEvent(event);
                        }

                        // PEAK DEMAND NUMERICAL INPUT
                        // Construct the id to match the <input> elements
                        const pdInputId = `num_ic_draft_all_IC-PEA`;
                        const pdInputElement = document.getElementById(pdInputId);

                        if (pdInputElement && category.formCode === "IC-PEA") {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(pdInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            pdInputElement.dispatchEvent(event);
                        }

                        //num_other_ic_${form.formFieldCode}
                        // other set NUMERICAL INPUTs
                        // Construct the id to match the <input> elements
                        const otherSetInputId = `num_other_ic_${category.formCode}`;
                        const otherSetInputElement = document.getElementById(otherSetInputId);

                        if (otherSetInputElement) {
                            const value = product.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                            nativeInputValueSetter.call(otherSetInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            otherSetInputElement.dispatchEvent(event);
                        }
                    });
                });
            });
            setInputsPopulated(true);
        }
    }, [fetchedReport, headers]);


    return (
        <div>
            <div class="table-responsive">
                <table id="installed-capacity-table" className="table-lg table-bordered table-hover">
                    <thead>
                        <tr>
                            {headers.map((column) => (
                                <th
                                    key={column.id}
                                    className="table-cell-minw-200"
                                    style={column.id === 'balance' ? {
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: 'white',
                                        zIndex: 1,
                                        borderRight: '1px solid #dee2e6'
                                    } : {}}
                                >
                                    {column.name}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.category ? (
                                    // Render category row with colSpan
                                    <td colSpan={headers.length}>
                                        {row.category}
                                    </td>
                                ) : (
                                    // Render product rows
                                    headers.map((column) => (
                                        <td
                                            key={column.id}
                                            className="table-cell-minw-200"
                                            style={column.id === 'balance' ? {
                                                position: 'sticky',
                                                left: 0,
                                                backgroundColor: 'white',
                                                zIndex: 1,
                                                borderRight: '1px solid #dee2e6'
                                            } :
                                                {}
                                            }
                                        >
                                            {row[column.id]}
                                        </td>

                                    ))
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <br />
                <table className="table-lg table-bordered table-hover">
                    <tbody>
                        <tr>
                            <td key="peakDemandKey" className="table-cell-minw-200">Peak Demand (MW)</td>
                            <td key="peakDemandValue" className="table-cell-minw-200">
                                <ClayInput
                                    className='no-spinner'
                                    key={`IC-PEA`}
                                    id={`num_ic_draft_all_IC-PEA`}
                                    style={inputStyle}
                                    type="number"
                                    name={`IC-PEA`}
                                    onChange={(e) => handleFuelValueChange(e, "-1", "all", "IC", "Peak Demand", "IC-PEA", false)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='d-flex justify-content-end'>
                    <FontAwesomeIcon title="Download as PDF" style={{ cursor: 'pointer' }} onClick={() => exportToPdf("installed-capacity-table", reportBasicInfo)} color='#6f6f6f' className='ml-3' size='xl' icon={faCloudDownload} />
                </div>
            </div>
            <div className="text-center mt-5 mb-3 d-flex justify-content-end"
            >
                {/* <ClayButton onClick={handleShowCheckingModal}>Check</ClayButton > */}
            </div>
            <Modal size='xl' show={showModal} onHide={handleCloseCheckingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body><span>Check all numbers in your Installed Capacity. </span>
                    <div className="text-center mt-0 mb-1 d-flex justify-content-start">
                        <table>
                            <tr>
                                {/* <td>
                    <ClayToggle
                      label="Checked"
                      onToggle={(newVal) => newVal === true ? (updateCheckedFormsCount(+1), setChecked(newVal)) : (updateCheckedFormsCount(-1),setChecked(newVal))} // Explicitly pass the new toggle state
                      toggled={checked}
                    />
                  </td> */}
                            </tr>
                        </table>
                    </div>
                    <div>

                        <InstalledCapacityTableCheckingDP
                            checkingReport={checkingReport} />
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    {/* <button className="btn btn-outline-secondary mr-1" onClick={handleCloseCheckingModal}>
            Close
          </button>
          <button
                onClick={() => submitReport("Draft")}
                className="btn btn-outline-primary mr-1"
              >
                Save As Draft
              </button>
          <button className="btn btn-primary mr-1" onClick={handleShowConfirmationModal}>
            Submit
          </button> */}

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default InstalledCapacityTableDP;
