import React, { useState, useEffect } from 'react';
import { Table, Head, Body, Row, Cell } from '@clayui/core';
import { ClaySelect, ClayInput } from '@clayui/form';
import { getProductAndForms } from '../../../../api/nebProductService';

const InstalledCapacityTable = ({ fetchedReport, selectedQuarter, selectedYear, reportStatus, combinedStructure, setCombinedStructure, reportMap, setReportMap }) => {

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [productAndFormsList, setProductList] = useState([]);
    const [inputsPopulated, setInputsPopulated] = useState(false);

    const [totals, setTotals] = useState({
        "IC-TIC": 0,
        "IC-TAC": 0,
        "IC-PEA": 0
    }
    );

    //API call runs only once, after the initial render
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms('0', 'IC');
                setProductList(result);
                console.log('ic: ' + JSON.stringify(result, null, 2))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const inputStyle = {
        textAlign: "right",
        width: "100%",
        height: "30px",
        marginRight: "5px",
        borderRadius: "5px"
    };


    const [fuelValues, setFuelValues] = useState([]);

    const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, formFieldCode, subtractive) => {
        console.log("icchange: " + index + ", " + productName + ", " + parentFormFieldCode + ", " + formFieldName, + ", " + formFieldCode)
        const { name, value: inputValue } = e.target;
        let value = parseFloat(inputValue);

        //Make value negative based on form type
        if (subtractive) {
            value = -Math.abs(value);
        }

        setFuelValues(prevFuelValues => {
            // Check if an object with matching formFieldCode exists
            const exists = prevFuelValues.some(item => item.productName === productName);

            if (exists) {
                // Update the existing object
                return prevFuelValues.map(item => {
                    if (item.index === index) {
                        // Find the form
                        let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
                        let updatedForms;

                        if (formIndex !== -1) {
                            // Update the existing form
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map(category => {
                                        if (category.formCode === name) {
                                            return { formCode: name, value: value, description: formFieldName };
                                        } else {
                                            return category;
                                        }
                                    });

                                    // If the category doesn't exist, add it
                                    if (!updatedCategories.some(category => category.formCode === name)) {
                                        updatedCategories.push({ formCode: name, value: value, description: formFieldName });
                                    }

                                    return { ...form, categories: updatedCategories };
                                } else {
                                    return form;
                                }
                            });
                        } else {
                            // Add a new form
                            updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
                        }

                        // Calculate the new balance
                        const balance = updatedForms.reduce((sum, form) => {
                            return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                        }, 0);

                        return { ...item, forms: updatedForms, balance: balance };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    index: index,
                    balance: value,
                    productName: productName,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                { formCode: name, value: value, description: formFieldName }
                            ]
                        }
                    ]
                };

                // Calculate the new balance
                const balance = newItem.forms.reduce((sum, form) => {
                    return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                }, 0);

                return [
                    ...prevFuelValues,
                    { ...newItem, balance: balance }
                ];
            }
        });
    };


    //calculate totals as input elements' value changes
    useEffect(() => {
        console.log("icfuel: ", JSON.stringify(fuelValues, null, 2));

        let _totalInstalledCapacitySum = 0;
        let _totalAvailableCapacitySum = 0;
        let _peakDemandSum = 0;

        fuelValues.forEach((product) => {
            product.forms.forEach((form) => {
                form.categories.forEach((category) => {
                    if (category.formCode === "IC-TIC") {
                        _totalInstalledCapacitySum += category.value;
                    } else if (category.formCode === "IC-TAC") {
                        _totalAvailableCapacitySum += category.value;
                    } else if (category.formCode === "IC-PEA") {
                        _peakDemandSum += category.value;
                    }
                });
            });
        });

        setTotals({
            "IC-TIC": _totalInstalledCapacitySum,
            "IC-TAC": _totalAvailableCapacitySum,
            "IC-PEA": _peakDemandSum
        }
        )
    }, [fuelValues]);

    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    useEffect(() => {
        console.log("debug Updated productAndUnit: ", JSON.stringify(productAndUnit, null, 2));
    }, [productAndUnit]);

    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        // Group products by their category (split by comma)
        const groupedProducts = productAndFormsList.reduce((acc, product) => {
            const [productType, category] = product.productName.split(',');
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(product);
            return acc;
        }, {});

        const dynamicRows = [];
        let categoryIndex = 1;
        let productIndex = 1;

        // Iterate over the grouped products by category
        for (const category in groupedProducts) {
            // Add a row for the category with colSpan extended to cover all columns
            dynamicRows.push({
                //no: categoryIndex,
                category:
                    (
                        <span>
                            <strong>{categoryIndex}. {category}</strong>
                        </span>
                    )
            });



            // Add the rows for each product under the current category
            groupedProducts[category].forEach((product, index) => {
                const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
                dynamicRows.push({
                    no: index + 1,
                    product: product.productName.split(',')[0], // Display only the product name
                    ...product.forms.reduce((acc, form) => {
                        //need only one peak demand input, not for every product/row

                        acc[form.formFieldCode] = (
                            <ClayInput disabled
                                key={`${index + 1}_${form.formFieldCode}`}
                                id={`num_${product.productName}_${form.formFieldCode}`}
                                style={inputStyle}
                                type="number"
                                name={form.formFieldCode}
                                onChange={(e) => handleFuelValueChange(e, product.productName, product.productName, form.parentFormFieldCode, form.formFieldName, form.formFieldCod, form.subtractive)}
                            />
                        );


                        return acc;
                    }, {}),
                    balance: (
                        <span style={{ paddingRight: "10px", display: "block", textAlign: "right" }}>
                            {fuelObject ? fuelObject.balance : 0}
                        </span>
                    ) // Handle undefined case
                });
            });

            categoryIndex++; // Increment category number
        }

        // Add "Other Sets" as the last category
        dynamicRows.push({
            no: categoryIndex,
            category: (
                <span>
                    <strong>{categoryIndex}. Other Sets</strong>
                </span>
            )
        });

        // Add one child product under "Other Sets" with a text ClayInput for the product name
        const otherForms = productAndFormsList[0].forms; // Assuming "Other Sets" has the same form structure as other products

        dynamicRows.push({
            no: categoryIndex + 1,
            product: (
                <ClayInput
                    disabled
                    type="text"
                    id="name_other_sets_ic"
                    onChange={(e) => console.log('Other Set Product:', e.target.value)} // You can handle the input value as needed
                />
            ),
            unit: '',
            ...otherForms.reduce((acc, form) => {
                acc[form.formFieldCode] = (
                    <ClayInput
                        disabled
                        key={`other_${form.formFieldCode}`}
                        style={inputStyle}
                        type="number"
                        name={form.formFieldCode}
                        id={`num_Other Sets_${form.formFieldCode}`}
                        onChange={(e) => handleFuelValueChange(e, categoryIndex, 'Other Sets', form.parentFormFieldCode, form.formFieldName, form.subtractive)}
                    />
                );
                return acc;
            }, {}),
            balance: '' // Empty balance (if needed)
        });

        console.log("ofs: " + JSON.stringify(otherForms, null, 2))


        //add "TOTAL" row
        dynamicRows.push({
            no: categoryIndex + 1,
            product: (
                <span>
                    <strong>TOTAL</strong>
                </span>
            ),
            unit: '',
            ...otherForms.reduce((acc, form) => {
                console.log(`total_${form.formFieldCode}: ${totals[form.formFieldCode]}`)
                acc[form.formFieldCode] = (
                    <div style={{ textAlign: 'center' }}> {/* Centering the text */}
                        <strong>{isNaN(Number(totals[form.formFieldCode])) ? 0 : Number(totals[form.formFieldCode])}</strong>
                    </div>
                    // <ClayInput
                    //     key={`total_${form.formFieldCode}`}
                    //     style={inputStyle}
                    //     type="number"
                    //     disabled={true}
                    //     value={totals[form.formFieldCode]}
                    //     name={`total_${form.formFieldCode}`}
                    // />
                );
                //console.log("acc: " + JSON.stringify(acc, null, 2))
                return acc;
            }, {}),
            balance: '' // Empty balance (if needed)
        });

        console.log("dyr: " + JSON.stringify(otherForms, null, 2))


        setRows(dynamicRows);
    }, [totals, productAndFormsList, fuelValues]);

    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            //{ id: 'no', name: 'No.' },
            { id: 'product', name: 'Types of Prime Mover' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName}`
            }))
        ];
        setHeaders(dynamicHeaders);
        console.log("hello: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    //merge

    // const [combinedStructure, setCombinedStructure] = useState(null);

    useEffect(() => {
        const productsMap = {};

        fuelValues.forEach(fuelItem => {
            // Ensure the product entry exists in productsMap
            if (!productsMap[fuelItem.productName]) {
                productsMap[fuelItem.productName] = {
                    productName: fuelItem.productName,
                    unit: productAndUnit.find(item => item.fieldName === fuelItem.productName)?.unit || 'MW',
                    forms: []  // Initialize forms array at the product level
                };
            }

            fuelItem.forms.forEach(form => {
                // Check if the form already exists for the product
                const existingFormIndex = productsMap[fuelItem.productName].forms.findIndex(
                    existingForm => existingForm.formCode === form.formCode
                );

                if (existingFormIndex === -1) {
                    // If the form does not exist for the product, add it
                    productsMap[fuelItem.productName].forms.push({
                        formCode: form.formCode,
                        categories: []  // Initialize categories as empty under the form
                    });
                }

                const targetForm = productsMap[fuelItem.productName].forms.find(f => f.formCode === form.formCode);

                // Now, we loop through categories and add them directly under the form
                form.categories.forEach(category => {
                    const categoryIndex = targetForm.categories.findIndex(
                        cat => cat.formCode === category.formCode
                    );

                    if (categoryIndex === -1) {
                        // If category doesn't exist, add it
                        targetForm.categories.push({
                            formCode: category.formCode,
                            description: category.description,
                            value: Math.abs(category.value)  // Ensure value is positive
                        });
                    } else {
                        // If category exists, update the value
                        targetForm.categories[categoryIndex].value = Math.abs(category.value);  // Ensure value is positive
                        targetForm.categories[categoryIndex].description = category.description;
                    }
                });
            });
        });

        const productsArray = Object.values(productsMap);

        // Set the updated report map with products, forms, and categories
        setReportMap(prevState => ({
            ...prevState,
            reportPeriodYear: selectedYear,
            reportQuarter: selectedQuarter,
            products: [
                ...(prevState.products || []).filter(
                    (product) => !productsArray.some((newProduct) => newProduct.productName === product.productName)
                ),
                ...productsArray
            ]
        }));

    }, [fuelValues, productAndUnit, selectedQuarter, selectedYear]);


    useEffect(() => {
        setCombinedStructure(reportMap);
    }, [reportMap]);

    //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
    useEffect(() => {
        console.log("received kat ic: " + JSON.stringify(fetchedReport, null, 2));

        if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated) {
            // Iterate through products, then forms, then categories in fetchedReport
            fetchedReport.products.forEach((product) => {
                product.forms.forEach((form) => {
                    form.categories.forEach((category) => {

                        // NUMERICAL INPUTS
                        // Construct the id to match the <input> elements
                        const numInputId = `num_${product.productName}_${category.formCode}`;
                        const numInputElement = document.getElementById(numInputId);
                        console.log("Processing product: " + JSON.stringify(product, null, 2));

                        if (numInputElement) {
                            const value = category.value;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                window.HTMLInputElement.prototype,
                                'value'
                            ).set;
                            nativeInputValueSetter.call(numInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            numInputElement.dispatchEvent(event);
                        }

                        // DROPDOWN INPUTS
                        // Construct the id to match the <select> elements
                        const selectInputId = `selectUnit_${product.productName}`;
                        const selectElement = document.getElementById(selectInputId);

                        if (selectElement) {
                            const value = product.unit;
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                window.HTMLSelectElement.prototype,
                                'value'
                            ).set;
                            nativeInputValueSetter.call(selectElement, value);

                            // Dispatch the 'change' event to trigger onChange in React
                            const event = new Event('change', { bubbles: true });
                            selectElement.dispatchEvent(event);
                        }

                        // OTHER SETS NAME INPUT
                        const otherSetId = `name_other_sets_ic`;
                        const nameElement = document.getElementById(otherSetId);

                        if (nameElement && product.productName.includes("Other Sets")) {
                            const value = product.productName.split(",")[0];
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                window.HTMLInputElement.prototype,
                                'value'
                            ).set;
                            nativeInputValueSetter.call(nameElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            nameElement.dispatchEvent(event);
                        }
                    });
                });
            });

            setInputsPopulated(true);
        }
    }, [fetchedReport, headers]);

    return (
        <div class="table-responsive">
            <table className="table-lg table-bordered table-hover">
                <thead>
                    <tr>
                        {headers.map((column) => (
                            <th
                                key={column.id}
                                className="table-cell-minw-200"
                                style={column.id === 'balance' ? {
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: 'white',
                                    zIndex: 1,
                                    borderRight: '1px solid #dee2e6'
                                } : {}}
                            >
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.category ? (
                                // Render category row with colSpan
                                <td colSpan={headers.length}>
                                    {row.category}
                                </td>
                            ) : (
                                // Render product rows
                                headers.map((column) => (
                                    <td
                                        key={column.id}
                                        className="table-cell-minw-200"
                                        style={column.id === 'balance' ? {
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: 'white',
                                            zIndex: 1,
                                            borderRight: '1px solid #dee2e6'
                                        } :
                                            {}
                                        }
                                    >
                                        {row[column.id]}
                                    </td>

                                ))
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <table className="table-lg table-bordered table-hover">
                <tbody>
                    <tr>
                        <td key="peakDemandKey" className="table-cell-minw-200">Peak Demand (MW)</td>
                        <td key="peakDemandValue" className="table-cell-minw-200">
                            <ClayInput disabled
                                key={`IC-PEA`}
                                id={`num_all_IC-PEA`}
                                style={inputStyle}
                                type="number"
                                name={`IC-PEA`}
                                onChange={(e) => handleFuelValueChange(e, "-1", "all", "IC", "Peak Demand", "IC-PEA", false)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default InstalledCapacityTable;
