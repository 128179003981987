

import React, { useState, useEffect, useRef } from 'react';
import { Table, Head, Body, Row, Cell } from '@clayui/core';
import { ClaySelect, ClayInput } from '@clayui/form';
import ClayButton from '@clayui/button';
import { Modal } from 'react-bootstrap';
import PlantPerformanceTableCheckingDP from './PlantPerformanceTableCheckingDP';
import { ClayToggle } from '@clayui/form';
import { getProductAndForms } from "../../../../api/nebProductService";
import { toast } from 'react-toastify';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import exportToPdf from './exportToPdf';

const PlantPerformanceTableDP = ({ viewMode, fetchedReport, updateCheckedFormsCount, selectedQuarter, selectedYear, combinedStructure, setCombinedStructure, reportMap, setReportMap,reportStatus }) => {
  const isDisabled = reportStatus === "Pending for Approval"|| reportStatus === "Approved";

  const [reportBasicInfo, setReportBasicInfo] = useState();
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [checked, setChecked] = useState(false);

  //API call runs only once, after the initial render
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms('0', 'POW');
        console.log('FETCHING plant forms' + JSON.stringify(result, null, 2));
        setProductAndFormsList(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const inputStyle = {
    textAlign: "right",
    width: "100%",
    height: "30px",
    marginRight: "5px",
    borderRadius: "5px"
  };


  const [fuelValues, setFuelValues] = useState([]);
  const [plantNames, setPlantNames] = useState([]);
  const [plantAndType, setPlantAndType] = useState([]);
  const [plantAndFuel, setPlantAndFuel] = useState([]);
  const plantNamesRef = useRef(plantNames);
  const plantAndTypeRef = useRef(plantAndType);
  const plantAndFuelRef = useRef(plantAndFuel);
  const handleShowCheckingModal = () => {
    setShowModal(true)
  };
  const handleCloseCheckingModal = () => {
    console.log("closing checking modal");
    setShowModal(false);
  };
  const [checkingReport, setCheckingReport] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleProductNameChange = (index, value) => {
    setPlantNames(prevProductNames => {
      const updatedProductNames = [...prevProductNames];
      const productIndex = updatedProductNames.findIndex(p => p.index === index);

      if (productIndex > -1) {
        updatedProductNames[productIndex].productName = value;
      } else {
        updatedProductNames.push({ index, productName: value });
      }

      return updatedProductNames;
    });
  };

  useEffect(() => {
    console.log("productnames: " + JSON.stringify(plantNames, null, 2))
    plantNamesRef.current = plantNames;
  }, [plantNames]);

  useEffect(() => {
    console.log("productnames productAndUnit: " + JSON.stringify(plantAndType, null, 2))
    plantAndTypeRef.current = plantAndType;
  }, [plantAndType]);

  useEffect(() => {
    console.log("productnames plantAndFuel: " + JSON.stringify(plantAndFuel, null, 2))
    plantAndFuelRef.current = plantAndFuel;
  }, [plantAndFuel]);

  useEffect(() => {
    console.log("productnames fuelValues: " + JSON.stringify(fuelValues, null, 2))
  }, [fuelValues]);

  const handleFuelValueChange = (e, index, parentFormFieldCode, formFieldName) => {
    const { name, value: inputValue } = e.target;
    let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue))

    if (value > 100) {
      toast.info("Percentage must be below 100");
      return;
    }

    setFuelValues(prevFuelValues => {
      // Check if an object with matching index exists
      const exists = prevFuelValues.some(item => item.index === index);

      if (exists) {
        // Update the existing object
        return prevFuelValues.map(item => {
          if (item.index === index) {
            // Find the form
            let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
            let updatedForms;

            if (formIndex !== -1) {
              // Update the existing form
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map(category => {
                    if (category.formCode === name) {
                      return {
                        formCode: name,
                        value: value,
                        description: formFieldName
                      };
                    } else {
                      return category;
                    }
                  });

                  // If the category doesn't exist, add it
                  if (!updatedCategories.some(category => category.formCode === name)) {
                    updatedCategories.push({
                      formCode: name,
                      value: value,
                      description: formFieldName
                    });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              // Add a new form
              updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
            }

            // Calculate the new balance
            const balance = updatedForms.reduce((sum, form) => {
              return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
            }, 0);

            return {
              ...item,
              forms: updatedForms,
              balance: isNaN(Number(balance)) ? 0 : Number(balance),
            };
          } else {
            return item;
          }
        });
      } else {
        //find matching plant by index
        const product = plantNamesRef.current.find(p => p.index === index);
        console.log("productnames match: " + JSON.stringify(plantNamesRef.current, null, 2))
        //find matching plant type by index
        const plantType = plantAndTypeRef.current.find(p => p.index === index);
        //find matching fuel by index
        const plantFuel = plantAndFuelRef.current.find(p => p.index === index);
        // Add a new object
        const newItem = {
          index: index,
          balance: isNaN(Number(value)) ? 0 : Number(value),
          productName: product ? product.productName : "-",
          plantType: plantType ? plantType.type : "-",
          fuel: plantFuel ? plantFuel.fuel : "-",
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                { formCode: name, value: value, description: formFieldName }
              ]
            }
          ],
        };

        // Calculate the new balance
        const balance = newItem.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0);

        return [
          ...prevFuelValues,
          { ...newItem, balance: isNaN(Number(balance)) ? 0 : Number(balance) }
        ];
      }
    });
  };

  useEffect(() => {
    console.log("fuel: ", JSON.stringify(fuelValues, null, 2));
  }, [fuelValues]);

  const handleTypeChange = (e, index) => {
    const { value: type } = e.target;
    setPlantAndType(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.index === index);
      console.log("plantname exists: " + exists)

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          console.log("plantname try matching: " + item.fieldName + ", " + index)
          if (item.index === index) {
            console.log("plantname matched: " + exists)
            return {
              ...item,
              type: type,
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          index: index,
          type: type,
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };

  const handleFuelChange = (e, index) => {
    const { value: fuel } = e.target;

    setPlantAndFuel(prevPlantAndFuel => {
      // Check if an object with matching productName exists
      const exists = prevPlantAndFuel.some(item => item.index === index);

      if (exists) {
        // Update the existing object
        return prevPlantAndFuel.map(item => {
          if (item.index === index) {
            return {
              ...item,
              fuel: fuel,
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          index: index,
          fuel: fuel,
        };

        return [
          ...prevPlantAndFuel,
          newItem
        ];
      }
    });
  };

  useEffect(() => {
    console.log("combined: ", JSON.stringify(combinedStructure, null, 2));
  }, [combinedStructure]);


  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;
    const dynamicRows = productAndFormsList.map((product, index) => {
      return {
        no: index + 1,
        product: <ClayInput
          id={`plantNameInput_draft_${index}`}
          disabled = {isDisabled}
          type="text"
          placeholder="Type..."
          onChange={(e) => handleProductNameChange(index, e.target.value)}
        >

        </ClayInput>,
        type: (
          <ClaySelect
            id={`selectType_plant_draft_${index}`}
            disabled={isDisabled}
            onChange={(e) => handleTypeChange(e, index)}>
            <option value="">Select...</option>
            <option value="CCGT">CCGT</option>
            <option value="OCGT">OCGT</option>
            <option value="Thermal">Thermal</option>
            <option value="Conventional Thermal">Conventional Thermal</option>

            {/* {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))} */}
          </ClaySelect>
        ),
        fuel: <ClaySelect
          id={`selectFuel_plant_draft_${index}`}
          disabled={isDisabled}
          onChange={(e) => handleFuelChange(e, index)}>
          <option value="">Select...</option>
          <option value="Hydro">Hydro</option>
          <option value="Mini Hydro">Mini Hydro</option>
          <option value="Gas">Gas</option>
          <option value="Coal">Coal</option>
          <option value="Diesel">Diesel</option>
          {/* {product.units.map((unit, unitIndex) => (
          <option
            key={unitIndex}
            value={unit.productUnitName}
          >
            {unit.productUnitName}
          </option>
        ))} */}
        </ClaySelect>,
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <ClayInput
              id={`num_plant_draft_${index}_${form.formFieldCode}`}
              key={`${index + 1}_${form.formFieldCode}`}
              disabled = {isDisabled}
              style={inputStyle}
              type="number"
              max={100}
              name={form.formFieldCode}
              onChange={(e) => handleFuelValueChange(e, index, form.parentFormFieldCode, form.formFieldName)}
            />
          );
          return acc;
        }, {}),
      }
    });

    setRows(dynamicRows);
  }, [productAndFormsList, fuelValues]);

  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    const dynamicHeaders = [
      { id: 'no', name: 'No.' },
      { id: 'product', name: 'Name of Power Plant' },
      { id: 'type', name: 'Type of Power Plant' },
      { id: 'fuel', name: 'Fuel' },
      ...productAndFormsList[0].forms.map(form => ({
        id: form.formFieldCode,
        name: `${form.formFieldName}`
      })),
    ];
    setHeaders(dynamicHeaders);
  }, [rows]);

  //merge

  // const [combinedStructure, setCombinedStructure] = useState(null);

  useEffect(() => {
    const formsMap = {};

    if (plantNames.length > 0 && plantAndType.length > 0 && plantAndFuel.length > 0) {
      console.log("plantNames: " + JSON.stringify(plantNames, null, 2));
      console.log("plantAndType: " + JSON.stringify(plantAndType, null, 2));
      console.log("plantAndFuel: " + JSON.stringify(plantAndFuel, null, 2));

      fuelValues.forEach(fuelItem => {
        fuelItem.forms.forEach(form => {
          if (!formsMap[form.formCode]) {
            formsMap[form.formCode] = {
              formCode: form.formCode,
              categories: []
            };
          }

          form.categories.forEach(category => {
            // Validate if all required objects are found
            const plant = plantNames.find(name => name.index === fuelItem.index);
            const type = plantAndType.find(type => type.index === fuelItem.index);
            const fuel = plantAndFuel.find(fuelObj => fuelObj.index === fuelItem.index);

            if (!plant || !type || !fuel) {
              console.error(`Missing data for fuelItem index ${fuelItem.index}`, {
                plant,
                type,
                fuel,
              });
              return; // Skip this category if any data is missing
            }

            const product = {
              index: fuelItem.index,
              fieldName: plant.productName,
              value: Math.abs(category.value), // ensure always positive
              plantType: type.type,
              fuel: fuel.fuel,
            };

            console.log("prd: " + JSON.stringify(product, null, 2));

            const categoryIndex = formsMap[form.formCode].categories.findIndex(
              cat => cat.formCode === category.formCode
            );

            if (categoryIndex !== -1) {
              formsMap[form.formCode].categories[categoryIndex].products.push(product);
            } else {
              formsMap[form.formCode].categories.push({
                formCode: category.formCode,
                description: category.description,
                products: [product]
              });
            }
          });
        });
      });
    }

    const formsArray = Object.values(formsMap);

    console.log("fb cat, reportMap.forms : ", JSON.stringify(...reportMap.forms, null, 2));
    console.log("fb cat, formsArray : ", JSON.stringify(...formsArray, null, 2));

    setReportMap(prevState => ({
      ...prevState,
      reportPeriodYear: selectedYear,
      reportQuarter: selectedQuarter,
      forms: [
        // Remove existing forms with the same formCode
        ...prevState.forms.filter(
          form => !formsArray.some(newForm => newForm.formCode === form.formCode)
        ),
        // Add the updated forms
        ...formsArray
      ]
    }));
  }, [fuelValues, plantNames, plantAndType, plantAndFuel, selectedQuarter, selectedYear]);

  useEffect(() => {
    console.log("got report: " + JSON.stringify(reportMap, null, 2))
    setCombinedStructure(reportMap);
    setCheckingReport(reportMap);
    //Get report basic info
    setReportBasicInfo(
      {
        "year": reportMap.reportPeriodYear,
        "quarter": reportMap.reportQuarter,
        "agency": reportMap.agency.agencyName,
        "dataProvider": reportMap.dataProvider.name,
        "status": reportMap.reportStatus,
        "type": "Plant Performance"
      }
    )
  }, [reportMap]);


  //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
  useEffect(() => {
    const setInputValue = (element, value, eventType) => {
      return new Promise((resolve) => {
        if (element) {
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            eventType === 'input' ? window.HTMLInputElement.prototype : window.HTMLSelectElement.prototype,
            'value'
          ).set;
          nativeInputValueSetter.call(element, value);

          // Dispatch the event to trigger onChange in React
          const event = new Event(eventType, { bubbles: true });
          element.dispatchEvent(event);
        }
        resolve();
      });
    };

    const populateInputs = async () => {
      if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated && viewMode === "draft") {
        console.log("received repo: " + JSON.stringify(fetchedReport, null, 2));

        for (const form of fetchedReport.forms) {
          for (const category of form.categories) {
            for (const product of category.products) {
              if (form.formCode === "POW") {

                // PLANT NAME INPUT (Process 1)
                const plantNameInputId = `plantNameInput_draft_${product.index}`;
                console.log("plantNameInputId: " + plantNameInputId);
                const plantNameElement = document.getElementById(plantNameInputId);
                await setInputValue(plantNameElement, product.fieldName, 'input');

                // PLANT TYPE DROPDOWN INPUT (Process 2)
                const selectInputId = `selectType_plant_draft_${product.index}`;
                console.log("populating " + selectInputId);
                const selectElement = document.getElementById(selectInputId);
                await setInputValue(selectElement, product.plantType, 'change');

                // PLANT FUEL DROPDOWN INPUT (Process 3)
                const selectFuelId = `selectFuel_plant_draft_${product.index}`;
                console.log("populating " + selectFuelId);
                const selectFuelElement = document.getElementById(selectFuelId);
                await setInputValue(selectFuelElement, product.fuel, 'change');

                // NUMERICAL INPUTS (Process 4)
                const numInputId = `num_plant_draft_${product.index}_${category.formCode}`;
                const numInputElement = document.getElementById(numInputId);
                await setInputValue(numInputElement, product.value, 'input');
              }
            }
          }
        }

        setInputsPopulated(true);
      }
    };

    populateInputs();
  }, [fetchedReport, headers]);




  return (
    <div>
      <div class="table-responsive">
        <table id="plant-performance-table" class="table-md table-bordered">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: 'white',
                        zIndex: 1,

                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust this based on the width of the first column
                          backgroundColor: 'white',
                          zIndex: 1,

                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map(row => (
              <tr key={row.no}>
                {headers.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' ?
                        "table-cell-no-width"
                        : "table-cell-minw-200"}
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: 'white',
                          zIndex: 1,
                          borderRight: '1px solid #dee2e6'
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '200px',  // Adjust this based on the width of the first column
                            backgroundColor: 'white',
                            zIndex: 1,
                            borderRight: '1px solid #dee2e6'
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
      <div className='d-flex justify-content-end'>
        <FontAwesomeIcon title="Download as PDF" style={{ cursor: 'pointer' }} onClick={() => exportToPdf("plant-performance-table", reportBasicInfo)} color='#6f6f6f' className='ml-3' size='xl' icon={faCloudDownload} />
      </div>
      <div className="text-center mt-5 mb-3 d-flex justify-content-end"
      >
        {/* <ClayButton onClick={handleShowCheckingModal}>Check</ClayButton > */}
        <Modal size='lg' show={showModal} onHide={handleCloseCheckingModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Submission</Modal.Title>
          </Modal.Header>
          <Modal.Body><span>Check all numbers and selections in your Plant Performance. Make sure you have completely filled all data in the form. </span>
            <div>
              <PlantPerformanceTableCheckingDP checkingReport={checkingReport} />

            </div>


          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PlantPerformanceTableDP;


