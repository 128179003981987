import React, { useState, useEffect } from 'react';
import { ClaySelect, ClayInput } from '@clayui/form';
import { getProductAndForms } from '../../../../api/nebProductService';

const NetCalorificTable = ({ fetchedReport, selectedQuarter, selectedYear, reportStatus, combinedStructure, setCombinedStructure, reportMap, setReportMap }) => {

    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [productAndFormsList, setProductList] = useState([]);
    const [inputsPopulated, setInputsPopulated] = useState(false);

    const [totals, setTotals] = useState({
        "total,Coal": 0,
        "total,Oil": 0,
        "total,Gas": 0
    }
    );

    //API call runs only once, after the initial render
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getProductAndForms('0', 'NETC');
                setProductList(result);
                console.log('ic: ' + JSON.stringify(result, null, 2))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const numInputStyle = {
        textAlign: "right",
        width: "100%",
        height: "40px",
        marginRight: "5px",
        borderRadius: "5px",
        background: "#f8f8f8",
        color: "black"
    };

    const selectInputStyle = {
        width: "100%",
        height: "40px",
        marginRight: "5px",
        borderRadius: "5px",
        background: "#f8f8f8",
        color: "black"
    }


    const [fuelValues, setFuelValues] = useState([]);

    const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, formFieldCode, subtractive) => {
        console.log("icchange: " + index + ", " + productName + ", " + parentFormFieldCode + ", " + formFieldName, + ", " + formFieldCode)
        const { name, value: inputValue } = e.target;
        let value = parseFloat(inputValue);

        //Make value negative based on form type
        if (subtractive) {
            value = -Math.abs(value);
        }

        setFuelValues(prevFuelValues => {
            // Check if an object with matching formFieldCode exists
            const exists = prevFuelValues.some(item => item.productName === productName);

            if (exists) {
                // Update the existing object
                return prevFuelValues.map(item => {
                    if (item.index === index) {
                        // Find the form
                        let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
                        let updatedForms;

                        if (formIndex !== -1) {
                            // Update the existing form
                            updatedForms = item.forms.map((form, idx) => {
                                if (idx === formIndex) {
                                    let updatedCategories = form.categories.map(category => {
                                        if (category.formCode === name) {
                                            return { formCode: name, value: value, description: formFieldName };
                                        } else {
                                            return category;
                                        }
                                    });

                                    // If the category doesn't exist, add it
                                    if (!updatedCategories.some(category => category.formCode === name)) {
                                        updatedCategories.push({ formCode: name, value: value, description: formFieldName });
                                    }

                                    return { ...form, categories: updatedCategories };
                                } else {
                                    return form;
                                }
                            });
                        } else {
                            // Add a new form
                            updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
                        }

                        // Calculate the new balance
                        const balance = updatedForms.reduce((sum, form) => {
                            return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                        }, 0);

                        return { ...item, forms: updatedForms, balance: balance };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    index: index,
                    balance: value,
                    productName: productName,
                    forms: [
                        {
                            formCode: parentFormFieldCode,
                            categories: [
                                { formCode: name, value: value, description: formFieldName }
                            ]
                        }
                    ]
                };

                // Calculate the new balance
                const balance = newItem.forms.reduce((sum, form) => {
                    return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
                }, 0);

                return [
                    ...prevFuelValues,
                    { ...newItem, balance: balance }
                ];
            }
        });
    };

    //calculate totals as input elements' value changes
    const [categoryTotals, setCategoryTotals] = useState([]);

    useEffect(() => {
        // Grouping products by category and calculating the total balance for each category
        const categoryMap = fuelValues.reduce((acc, product) => {
            const category = product.productName.split(',')[1]; // Extract category after the comma
            if (!acc[category]) {
                acc[category] = { products: [], total: 0 };
            }
            acc[category].products.push(product);
            acc[category].total += product.balance;
            return acc;
        }, {});

        // Build the final output array
        let finalOutput = [];
        for (const [category, { products, total }] of Object.entries(categoryMap)) {
            finalOutput = [...finalOutput, ...products, { [`total,${category}`]: total }];
        }

        setCategoryTotals(finalOutput);
    }, [fuelValues]);

    useEffect(() => {
        console.log("cat: " + JSON.stringify(categoryTotals, null, 2))
    }, [categoryTotals])

    const [productAndUnit, setProductAndUnit] = useState([]);

    const handleUnitChange = (e, index, productName) => {
        const { value: unit } = e.target;

        setProductAndUnit(prevProductAndUnit => {
            // Check if an object with matching productName exists
            const exists = prevProductAndUnit.some(item => item.fieldName === productName);

            if (exists) {
                // Update the existing object
                return prevProductAndUnit.map(item => {
                    if (item.fieldName === productName) {
                        return {
                            ...item,
                            unit: unit
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                // Add a new object
                const newItem = {
                    fieldName: productName,
                    unit: unit
                };

                return [
                    ...prevProductAndUnit,
                    newItem
                ];
            }
        });
    };

    useEffect(() => {
        console.log("debug Updated productAndUnit: ", JSON.stringify(productAndUnit, null, 2));
    }, [productAndUnit]);


    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        // Group products by their category (split by comma)
        const groupedProducts = productAndFormsList.reduce((acc, product) => {
            const [productType, category] = product.productName.split(',');
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(product);
            return acc;
        }, {});

        const dynamicRows = [];
        let categoryIndex = 1;

        // Iterate over the grouped products by category
        for (const category in groupedProducts) {
            // Add a row for the category with colSpan extended to cover all columns
            dynamicRows.push({
                category: (
                    <span>
                        <strong>{categoryIndex}. {category}</strong>
                    </span>
                )
            });

            // Add the rows for each product under the current category
            groupedProducts[category].forEach((product, index) => {
                const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
                dynamicRows.push({
                    no: index + 1,
                    product: product.productName.split(',')[0], // Display only the product name
                    ...product.forms.reduce((acc, form) => {
                        acc[form.formFieldCode] = (
                            <ClayInput
                                disabled
                                key={`${index + 1}_${form.formFieldCode}`}
                                style={numInputStyle}
                                type="number"
                                id={`num_${product.productName}_${form.formFieldCode}`}
                                name={form.formFieldCode}
                                onChange={(e) => handleFuelValueChange(e, product.productName, product.productName, form.parentFormFieldCode, form.formFieldName, form.formFieldCode, form.subtractive)}
                            />
                        );
                        return acc;
                    }, {}),
                    unit: (
                        <ClaySelect
                            disabled
                            style={selectInputStyle}
                            id={`selectUnit_${product.productName}`}
                            onChange={(e) => handleUnitChange(e, index, product.productName, product.units[e.target.selectedIndex - 1].inKtoe)}>
                            <option value="">Select...</option>
                            {product.units.map((unit, unitIndex) => (
                                <option key={unitIndex} value={unit.productUnitName}>
                                    {unit.productUnitName}
                                </option>
                            ))}
                        </ClaySelect>
                    ),
                });
            });

            // Add the total row for the category
            // const totalForCategory = categoryTotals.find(item => item[`total,${category}`]);
            // if (totalForCategory) {
            //     console.log("found!");
            //     dynamicRows.push({
            //         category: (
            //             // <span>
            //             //     <strong>TOTAL</strong>
            //             //     <ClayInput
            //             //         key={`total,${category}`}
            //             //         style={inputStyle}
            //             //         type="number"
            //             //         name={`total,${category}`}
            //             //         disabled={true}
            //             //         value={totalForCategory[`total,${category}`]}
            //             //     />

            //             // </span>
            //             // <span>
            //             //     <strong>Total {category}: {totalForCategory[`total,${category}`]}</strong>
            //             // </span>
            //         )
            //     });
            // }

            // categoryIndex++; // Increment category number
        }

        setRows(dynamicRows);
    }, [categoryTotals, productAndFormsList, fuelValues]);


    //After rows changes, headers is formed
    useEffect(() => {
        if (productAndFormsList.length === 0) return;

        const dynamicHeaders = [
            //{ id: 'no', name: 'No.' },
            { id: 'product', name: 'Product' },
            ...productAndFormsList[0].forms.map(form => ({
                id: form.formFieldCode,
                name: `${form.formFieldName}`
            })),
            { id: 'unit', name: 'Unit' },

        ];
        setHeaders(dynamicHeaders);
        console.log("dyn: " + JSON.stringify(dynamicHeaders, null, 2))
    }, [rows]);

    //merge

    // const [combinedStructure, setCombinedStructure] = useState(null);

    useEffect(() => {
        const productsMap = {};

        // Iterate over each fuel item
        fuelValues.forEach(fuelItem => {
            // Iterate over the forms for each fuel item
            fuelItem.forms.forEach(form => {
                // Ensure the form entry exists in the productsMap
                if (!productsMap[form.formCode]) {
                    productsMap[form.formCode] = {
                        formCode: form.formCode,
                        categories: []  // Initialize categories under the form
                    };
                }

                // Now, iterate over each category within the form
                form.categories.forEach(category => {
                    const product = {
                        fieldName: fuelItem.productName,
                        value: Math.abs(category.value), // ensure value is positive
                        unit: productAndUnit.find(item => item.fieldName === fuelItem.productName)?.unit || 'MW' // Get the unit
                    };

                    // We need to find if the category already exists in the form
                    const categoryIndex = productsMap[form.formCode].categories.findIndex(
                        cat => cat.formCode === category.formCode
                    );

                    if (categoryIndex === -1) {
                        // If the category doesn't exist, create a new one
                        productsMap[form.formCode].categories.push({
                            formCode: category.formCode,
                            description: category.description,
                            value: category.value, // Initial category value
                            total: totals[category.formCode] // Add total value from totals
                        });
                    }

                    // We do not add products to categories anymore, the structure is flat
                });
            });
        });

        const formsArray = Object.values(productsMap);

        // Set the updated report map with the correct structure
        setReportMap(prevState => ({
            ...prevState,
            reportPeriodYear: selectedYear,
            reportQuarter: selectedQuarter,
            forms: [
                // Remove existing forms with the same formCode
                ...prevState.forms.filter(form => !formsArray.some(newForm => newForm.formCode === form.formCode)),
                // Add the updated forms
                ...formsArray
            ]
        }));

    }, [totals, fuelValues, productAndUnit, selectedQuarter, selectedYear]);


    useEffect(() => {
        setCombinedStructure(reportMap);
        console.log("rmap: " + JSON.stringify(reportMap, null, 2))
    }, [reportMap]);

    //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
    useEffect(() => {
        if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated) {
            // Iterate through the products, then forms, then categories in fetchedReport
            fetchedReport.products.forEach((product) => {
                product.forms.forEach((form) => {
                    form.categories.forEach((category) => {

                        // NUMERICAL INPUTS
                        // Construct the id to match the <input> elements
                        const numInputId = `num_${product.productName}_${category.formCode}`;
                        const numInputElement = document.getElementById(numInputId);

                        if (numInputElement) {
                            const value = category.value; // Value from the category level
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                window.HTMLInputElement.prototype,
                                'value'
                            ).set;
                            nativeInputValueSetter.call(numInputElement, value);

                            // Dispatch the 'input' event to trigger onChange in React
                            const event = new Event('input', { bubbles: true });
                            numInputElement.dispatchEvent(event);
                        }

                        // DROPDOWN INPUTS
                        // Construct the id to match the <select> elements
                        const selectInputId = `selectUnit_${product.productName}`;
                        const selectElement = document.getElementById(selectInputId);

                        if (selectElement) {
                            const value = product.unit; // Unit from the product level
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                window.HTMLSelectElement.prototype,
                                'value'
                            ).set;
                            nativeInputValueSetter.call(selectElement, value);

                            // Dispatch the 'change' event to trigger onChange in React
                            const event = new Event('change', { bubbles: true });
                            selectElement.dispatchEvent(event);
                        }
                    });
                });
            });
            setInputsPopulated(true);
        }
    }, [fetchedReport, headers]);


    return (
        <div class="table-responsive">
            <table className="table-lg table-bordered table-hover">
                <thead>
                    <tr>
                        {headers.map((column) => (
                            <th
                                key={column.id}
                                className="table-cell-minw-200"
                                style={column.id === 'balance' ? {
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: 'white',
                                    zIndex: 1,
                                    borderRight: '1px solid #dee2e6'
                                } : {}}
                            >
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.category ? (
                                // Render category row with colSpan
                                <td colSpan={headers.length}>
                                    {row.category}
                                </td>
                            ) : (
                                // Render product rows
                                headers.map((column) => (
                                    <td
                                        key={column.id}
                                        className="table-cell-minw-200"
                                        style={column.id === 'balance' ? {
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: 'white',
                                            zIndex: 1,
                                            borderRight: '1px solid #dee2e6'
                                        } : {}}
                                    >
                                        {row[column.id]}
                                    </td>
                                ))
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default NetCalorificTable;
