import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { ClaySelect, ClayInput } from '@clayui/form';
import { getSubmissionList } from "../../../api/nebReportService";
import { Body, Cell, Head, Row as RowClay, Table } from "@clayui/core";
import ClayCard from "@clayui/card";
import { Card, Badge, FormLabel } from "react-bootstrap";
import ClayButton from "@clayui/button";
import ClayLayout from "@clayui/layout";
import Label from "@clayui/toolbar/lib/Label";

function VerificationList() {

  //get current year and quarter
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentQuarter = "Q" + Math.ceil((date.getMonth() + 1) / 3);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allYearSelected, setAllYearSelected] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState();
  const [yearList, setYearList] = useState([]);
  const [quarterList, setQuarterList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    const fetchList = async () => {
      try {
        const result = await getSubmissionList();

        //filter out Draft status reports
        const noDraft = result.data.filter(report => report.reportStatus !== "Draft");
        setData(noDraft);
        console.log("ver result: " + JSON.stringify(noDraft, null, 2));
        setFilteredData(noDraft);
        console.log("getlist: " + JSON.stringify(result, null, 2));
      } catch (error) {
        console.error('Error fetching list:', error);
      } finally {
        setListLoading(false);
      }
    };

    fetchList();
    setSelectedYear(currentYear.toString());
    setSelectedQuarter(currentQuarter);
    //setYearList([(currentYear - 2).toString(), (currentYear - 1).toString(), (currentYear).toString()]);
    console.log("selectedYear: " + currentYear + typeof (currentYear));
  }, []);

  useEffect(() => {
    console.log("submissionlist:", JSON.stringify(data, null, 2));
    // get years
    const yearSet = new Set();
    data.forEach(form => {
      yearSet.add(form.reportPeriodYear)
    });
    setYearList(Array.from(yearSet).sort((a, b) => b - a));
  }, [data]);

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  }

  //filter with search term, quarter and year
  useEffect(() => {
    if (searchKeyword.trim() === "") {
      const filtered = data.filter(item => item.reportPeriodYear === selectedYear && item.reportQuarter === selectedQuarter);
      setFilteredData(filtered);
    } else {
      const searchResult = filterReportsForSearch(filteredData, searchKeyword);
      setFilteredData(searchResult);
    }
  }, [searchKeyword, data, selectedYear, selectedQuarter]);

  const filterReportsForSearch = (reports, keyword) => {
    return reports.filter((report) => {
      const valuesToSearch = [
        report.id || '', // Handle undefined or null values
        report.modifiedDate || '',
        report.reportStatus || '',
        report.reportQuarter || '',
        report.reportPeriodYear || '',
        report.agency?.agencyName || '', // Optional chaining for nested properties
        report.dataProvider?.name || '', // Optional chaining for nested properties
      ].join(' ').toLowerCase();

      return valuesToSearch.includes(keyword.toLowerCase());
    });
  };

  const [goToSubmitNewReport, setGoToSubmitNewReport] = React.useState(false);

  if (goToSubmitNewReport) {
    return <Navigate to="/viewReport" />;
  }


  const handleYearChange = (event) => {
    let currentValue = event.target.value;
    setSelectedYear(currentValue);
    // if (currentValue === "all") {
    //   setAllYearSelected(true)
    //   setSelectedYear(`${yearList[0]} - ${yearList[yearList.length - 1]}`);
    // } else {
    //   setAllYearSelected(false)
    //   setSelectedYear(currentValue);

    // }
  };

  const handleQuarterChange = (event) => {
    setSelectedQuarter(event.target.value);
  }

  const formatDateTime = (dateTimeString) => {
    if (dateTimeString || dateTimeString !== "" || dateTimeString !== null || dateTimeString !== undefined) {
      const date = new Date(dateTimeString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const time = date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });

      return `${day}/${month}/${year}`;
    } else {
      return "n/a";
    }
  };

  return (
    <>
      {/* <div className="main-search mb-3 mr-4">
        <div>
          <ClaySelect id="year" value={selectedYear} onChange={handleYearChange}>
            <option value="all">All</option>
            {yearList.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </ClaySelect>
        </div>
      </div> */}
      <ClayCard className="mb-3" style={{ borderRadius: "20px", position: 'relative' }}>
        {/* Input moved here */}
        <ClayInput
          value={searchKeyword}
          type="text"
          onChange={(event) => handleSearchChange(event)}
          placeholder="Search"
          style={{
            position: 'absolute',
            top: '30px',
            left: '15px',
            width: '200px', // Adjust the width as needed
            padding: '5px',
            borderRadius: '5px'
          }}
        />

        <div className=""></div>
        <div className="">
          <Card.Header>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                display: 'flex',
                gap: '20px',
                marginRight: '50px'
              }}
            >
              {/* Other elements stay in place */}
              <ClayInput.Group>
                <ClayInput.GroupItem>
                  <FormLabel>Year</FormLabel>
                  <ClaySelect id="year" value={selectedYear} onChange={handleYearChange}>
                    {yearList.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </ClaySelect>
                </ClayInput.GroupItem>
              </ClayInput.Group>

              <ClayInput.Group>
                <ClayInput.GroupItem>
                  <FormLabel>Quarter</FormLabel>
                  <ClaySelect id="quarter" value={selectedQuarter} onChange={handleQuarterChange}>
                    <option value="Q1" >Q1 </option>
                    <option value="Q2" >Q2 </option>
                    <option value="Q3" >Q3 </option>
                    <option value="Q4" >Q4 </option>
                  </ClaySelect>
                </ClayInput.GroupItem>
              </ClayInput.Group>
            </div>
            <br /><br /><br />
          </Card.Header>
        </div>

        <Card.Body className="p-3" style={{ overflowX: "auto" }}>
          {listLoading ? <p>Loading...</p> :
            <Table>
              <thead className="text-dark">
                <tr>
                  <th>No</th>
                  <th>Agency</th>
                  <th>Status</th>
                  <th>Approver</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredData.length === 0 ? <tr><td colSpan={5}>No report found for {selectedQuarter}, {selectedYear} or from the search query.</td></tr> : filteredData.map((d, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{d.agency.agencyName}</td>
                    <td>
                      <span
                        className={`badge ${d.reportStatus === "Approved"
                          ? "bg-success"
                          : d.reportStatus === "Pending for Approval"
                            ? "bg-warning"
                            : d.reportStatus === "Pending for Amendment"
                              ? "bg-danger"
                              : "bg-secondary"
                          }`}
                      >
                        {d.reportStatus}
                      </span>
                    </td>
                    <td>Admin</td>

                    <td>
                      <div>
                        <Link
                          to={`/neb-verification/neb-verification-view/${d.id}/${d.agency.id}/${d.agency.agencyName}/${d.reportQuarter}/${d.reportPeriodYear}/${d.reportStatus}`}
                          className=""
                        >
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </Card.Body>
      </ClayCard>

    </>
  );
}

export default VerificationList;
